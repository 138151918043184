import React, { useEffect, useState } from "react"
import classes from "./Banner.module.css"
import logo from "../../assets/image/png/logo 150yrs@2x.png"
import banerImage from "../../assets/image/png/bannerimage.png"
import CustomButton from "../../components/UI/Button/Button"
import { useNavigate } from "react-router-dom"
import useApiHttp from "../../hooks/ues-http"
import { setSearchTerm } from "../../services/storeSlice/shopNowSlice"
import { useDispatch } from "react-redux"
const Banner = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    isLoading: RecentSearchLoading,
    success: RecentSearchSucces,
    error: RecentSearchError,
    sendRequest: RecentSearchRequest,
  } = useApiHttp()

  const [recentdata, setRecent] = useState([])

  const recentSearchRequestHandle = () => {
    RecentSearchRequest(
      {
        url: `dealer/home/recent-searches`,
      },
      data => {
        setRecent(data.data)
      }
    )
  }

  useEffect(() => {
    recentSearchRequestHandle()
  }, [])

  const companyname = localStorage.getItem("userName")
  const contactName = localStorage.getItem("contactName")

  return (
    <div className={classes.banner}>
      <div className={classes.container}>
        <div className={classes.container_left}>
          <img src={logo} alt="logo" />
          <h3>
            Welcome <span className="staroge">{contactName}!</span>
          </h3>
          <p>
            Easily search and navigate with a single click using our intelligent
            search feature.
          </p>
          <CustomButton
            variant="contained"
            customColor="white"
            customBgColor="#32349b"
            custmstyle={{
              padding: "10px 7px",
              width: "200px",
              marginTop: "20px",
              borderRadius: "8px",
            }}
            onClick={() => navigate("/shopnow")}
          >
            Explore and Order
          </CustomButton>
          <div className={classes.contant_list}>
            {recentdata?.length > 0 && (
              <p className={classes.contant_titel}>Recent Searches</p>
            )}
            <div className={classes.list_align}>
              <ul>
                {recentdata?.map((item, index) => (
                  <li
                    key={item.token}
                    onClick={() => {
                      navigate("/shopnow")
                      dispatch(setSearchTerm(item.value))
                    }}
                  >{`${index + 1}) ${item.value}`}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={classes.container_right}>
          <img src={banerImage} alt="banerImage" />
        </div>
      </div>
    </div>
  )
}

export default Banner
