import React, { useState } from "react"
import classes from "./EditableProductGrid.module.css"
import EditableProduct from "../../../components/UI/Table/EditableProduct"
import upload from "../../../assets/image/svg/uploadcolor.svg"
import CustomButton from "../../../components/UI/Button/Button"
import Qty from "../../../components/UI/Qty/Qty"
import { currentDate } from "../../../utils/helper"
import dayjs from "dayjs"
import CustomDatePicker from "../../../components/UI/DatePicker/DatePicker"
import alert from "../../../assets/image/svg/alertreupload.svg"
import {
  fetchList,
  setCsvProducts,
  uploadCsvTable,
} from "../../../services/storeSlice/shopNowSlice" // Your action for handling API calls
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { Modal, Box, Typography, Button } from "@mui/material"

const EditableProductGrid = ({ products }) => {
  const dispatch = useDispatch()
  const data = useSelector(item => item.shopNow)

  const [isOpen, setIsOpen] = useState(false)
  const [addtoCart, setAddtoCart] = useState(
    products.map(product => ({
      token: product.token,
      quantity: product.quantity || 1,
      date: product.date || dayjs(currentDate()).format("YYYY-MM-DD"),
    }))
  )

  const handleQuantityBlur = (index, value, productMinQuantity) => {
    const updatedCart = [...addtoCart]
    updatedCart[index].quantity =
      value < productMinQuantity ? productMinQuantity : value

    setAddtoCart(updatedCart)
  }

  const handleDateChange = (index, value) => {
    const updatedCart = [...addtoCart]
    updatedCart[index].date = dayjs(value).format("YYYY-MM-DD")
    setAddtoCart(updatedCart)
  }

  // Columns for the table
  const columns = [
    {
      name: "S.No",
      selector: row => row.sl_no,
      width: "150px",
    },
    {
      name: "Product Code",
      selector: row => row.product_code,
      width: "150px",
    },
    {
      name: "Description",
      selector: row => row.description,
      width: "150px",
    },
    {
      name: "Category",
      selector: row => row.category,
      width: "150px",
    },
    {
      name: "Sub-Category",
      selector: row => row.sub_category,
      width: "150px",
    },
    {
      name: "Material",
      selector: row => row.material,
      width: "150px",
    },
    {
      name: "Coating",
      selector: row => row.coating,
      width: "150px",
    },
    {
      name: "Size",
      selector: row => row.size,
      width: "150px",
    },
    {
      name: "MOQ",
      selector: row => row.moq,
      width: "150px",
    },
    {
      name: "Quantity",
      cell: (row, index) => (
        <Qty
          type={"number"}
          label={"Qty"}
          onChange={e => {
            const updatedCart = [...addtoCart]
            updatedCart[index].quantity = e.target.value
            setAddtoCart(updatedCart)
          }}
          onBlur={e => handleQuantityBlur(index, e.target.value, row.moq)}
          value={addtoCart[index].quantity}
        />
      ),
      width: "150px",
    },
    {
      name: "Needed Date",
      cell: (row, index) => (
        <CustomDatePicker
          sx={{
            borderRadius: "5px",
            width: "154px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              width: "154px",
              minWidth: "0px",
            },
            "& .css-10o2lyd-MuiStack-root>.MuiTextField-root": {
              minWidth: "0px",
            },
            "& .MuiFormControl-root": {
              minWidth: "0px",
            },
          }}
          format="DD-MM-YYYY"
          minDate={dayjs(currentDate())}
          value={dayjs(addtoCart[index].date)}
          onChange={value => handleDateChange(index, value)}
        />
      ),
      width: "200px",
    },
  ]

  const handleAddToSummary = async () => {
    const today = dayjs(currentDate()).format("YYYY-MM-DD")

    const invalidDateProducts = addtoCart.filter(item =>
      dayjs(item.date).isBefore(today)
    )

    if (invalidDateProducts.length > 0) {
      toast.error(
        "Please ensure all needed dates are on or after today's date."
      )
      return
    }

    dispatch(
      uploadCsvTable({
        body: {
          products: addtoCart,
        },
      })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchList())
        toast.success("Summary successfully added!")
      })
      .catch(error => {
        toast.error("Failed to add summary.")
      })
  }

  return (
    <div style={{ height: 400, width: "100%" }}>
      <EditableProduct
        data={products}
        columns={columns}
        children={
          <div className={classes.header_table}>
            <h3>{products.length} Results</h3>
            <div className={classes.right_button}>
              <div
                className={classes.downlod_csv_button}
                onClick={() => setIsOpen(true)}
              >
                <img src={upload} alt="download" />
                <p>Re-Upload the CSV</p>
              </div>
              <CustomButton
                variant="contained"
                customColor="white"
                customBgColor="#32349b"
                custmstyle={{
                  padding: "5px 7px",
                  width: "130px",
                  borderRadius: "6px",
                  fontSize: "14px",
                }}
                onClick={handleAddToSummary}
              >
                Add to Summary
              </CustomButton>
            </div>
          </div>
        }
      />
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={false}
          aria-labelledby="popup-modal-title"
          aria-describedby="popup-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              maxWidth: "500px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
            }}
          >
            <Box
              id="popup-modal-description"
              sx={{
                textAlign: "center",
              }}
            >
              <img src={alert} alt="alert" />
              <p className={classes.title_alert}>Alert!</p>
              <p className={classes.sub_title_alert}>
                Reuploading a CSV file will delete the current file. You will be
                redirected to the upload page. Are you sure want to continue?
              </p>
            </Box>
            {/* Action Buttons */}
            <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{
                  color: "#68686A",
                  fontSize: "14px",
                  fontFamily: "var(--font-bold)",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  color: "#32349B",
                  fontSize: "14px",
                  fontFamily: "var(--font-bold)",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  dispatch(setCsvProducts(null))
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  )
}

export default EditableProductGrid
