import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from '../modules/dealer/pages/Login/Login';
import MainLayout from '../modules/dealer/layout/MainLayout';
import OtpPage from '../modules/dealer/pages/Login/OtpPage';
import Home from '../modules/dealer/pages/Home/Home';
import ShopNow from '../modules/dealer/pages/ShopNow/ShopNow';
import MyOrders from '../modules/dealer/pages/MyOrders/MyOrders';
import RaiseComplaint from '../modules/dealer/pages/RaiseComplaint/RaiseComplaint';
import { Provider } from 'react-redux';
import store from '../modules/dealer/store/reduxStore';
import Test from '../modules/dealer/pages/OrderSummary';
import MyProfile from '../modules/dealer/pages/MyProfile/MyProfile';
import ViewMyOrder from '../modules/dealer/pages/ViewMyOrder/ViewMyOrder';
import ViewComplaint from '../modules/dealer/pages/ViewComplaint/ViewComplaint';

const routes = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/otp',
    element: <OtpPage />
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,  // This is now the index route, no children here
      },
      {
        path: '/shopnow',
        element: <ShopNow />,
      },
      {
        path: '/myorders',
        element: <MyOrders />,
      },
      {
        path: '/raisecomplaint',
        element: <RaiseComplaint />,
      },
      {
        path: '/myprofile',
        element: <MyProfile />,
      },
      {
        path: 'view_my_order/:token',
        element: <ViewMyOrder />,
      },
      {
        path: 'view_complaint/:token',
        element: <ViewComplaint />,
      }
    ]
  },
]);

export default function Dealers() {
  return (
    <Provider store={store}>
       <RouterProvider router={routes} />
    </Provider>
  );
}
