import React, { useEffect, useState } from "react"
import classes from "./Summary.module.css"
import CustomButton from "../../components/UI/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import remove from "../../assets/image/svg/remove.svg"
import {
  fetchList,
  fetchRemoveList,
  updateCartItems,
  updateQtyDate,
  setSteperVlue,
} from "../../services/storeSlice/shopNowSlice"
import CustomDatePicker from "../../components/UI/DatePicker/DatePicker"
import Qty from "../../components/UI/Qty/Qty"
import dayjs from "dayjs"
import { currentDate } from "../../utils/helper"
import { toast, ToastContainer } from "react-toastify"
import { resetTimer, startTimer } from "../../services/storeSlice/timerSlice"
import useApiHttp from "../../hooks/ues-http"

const Summary = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.shopNow)
  const [dateError, setDateError] = useState({}) // state to keep track of date validation errors
  console.log("dateError", dateError)

  const cart = data?.cartData?.cart
  const cartItem = data?.cartData?.cart_items
  console.log("cart", data)

  const removeCartIteme = token => {
    dispatch(fetchRemoveList({ body: { cart_item_token: token } }))
      .unwrap()
      .then(() => {
        dispatch(fetchList())
      })
  }

  useEffect(() => {
    if (data.updateQtyDateStatus === "succeeded") {
      dispatch(fetchList())
    }
  }, [data.updateQtyDateStatus])

  const {
    isLoading: reserveLoading,
    success: reserveSucces,
    error: reserveError,
    sendRequest: reserveRequest,
  } = useApiHttp()

  const cartItems = cartItem?.map(item => ({
    cart_item_token: item.cart_item_token,
    product_token: item.product_token,
    requested_date: item.requested_date,
    quantity: item.product_quantity,
  }))

  const reserveHandleSelect = () => {
    reserveRequest(
      {
        url: `dealer/shop-now/reserve-products`,
        method: "POST",
        body: {
          cart_token: cart?.cart_token,
          cart: cartItems,
        },
      },
      data => {
        if (data?.status_code === 200) {
          dispatch(startTimer())
        }
      }
    )
  }

  const handlerContinue = () => {
    const hasDateError = Object.values(dateError).some(error => error === true)

    if (hasDateError) {
      toast.success("Please resolve the date errors before proceeding.", {
        // position: toast.POSITION.TOP_RIGHT,
      })
      return
    }

    if (cartItem?.length > 0) {
      dispatch(setSteperVlue(1))
      localStorage.setItem("active", 1)
      reserveHandleSelect()
    } else {
      toast.error("Please add at least one product to continue.", {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  // Helper function to validate the date and set error
  const validateDate = (index, value) => {
    const selectedDate = dayjs(value)
    if (selectedDate.isBefore(dayjs(currentDate()))) {
      setDateError(prevState => ({ ...prevState, [index]: true }))
      return false
    } else {
      setDateError(prevState => ({ ...prevState, [index]: false }))
      return true
    }
  }

  return (
    <div className={classes.sammary}>
      <div className={classes.sammary_card}>
        <div className={classes.sammary_card_left}>
          <h3>Summary</h3>
          <p>
            {cartItem?.length && cartItem?.length ? cartItem?.length : 0}{" "}
            Products
          </p>
        </div>
        <div className={classes.sammary_card_right}>
          <div className={classes.card}>
            <div className={classes.card_contant}>
              <p>Order Total Value</p>
              <label className={classes.card_value}>
                {cart && (
                  <span className="orginal_price">
                    ₹{" "}
                    {cart?.total_amount
                      ? cart?.total_amount.toLocaleString("en-IN")
                      : 0}
                  </span>
                )}
                {cart &&
                  !isNaN(cart?.total_item_mrp + cart?.total_tax_amount) && (
                    <span className="line_through_price">
                      ₹{" "}
                      {(
                        cart?.total_item_mrp + cart?.total_tax_amount
                      ).toLocaleString("en-IN")}
                    </span>
                  )}
              </label>
            </div>
            <CustomButton
              variant="contained"
              customColor="white"
              customBgColor="#32349b"
              custmstyle={{
                padding: "5px 12px",
                width: "100px",
                borderRadius: "6px",
              }}
              onClick={handlerContinue}
            >
              Continue
            </CustomButton>
          </div>
        </div>
      </div>
      <div className={classes.card_item}>
        {cartItem?.length > 0 && (
          <>
            {cartItem.map((item, index) => (
              <div className={classes.sammary_card_item} key={index}>
                <div className={classes.summary_detail}>
                  <div>
                    <p className={classes.sno}>{index + 1}.</p>
                  </div>
                  <div>
                    <h3>{item.product_code}</h3>
                    <h5>{item.name}</h5>
                    <p>{item.sub_name}</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    width: "56%",
                    gap: "1rem",
                  }}
                >
                  <div className={classes.sammary_card_item_right}>
                    <div>
                      <label
                        className={`label_widgets ${
                          item.stock_status === 1 ? "in_stock" : "out_stock"
                        }`}
                      >
                        {item.stock_status === 1 ? "In Stock" : "Out of Stock"}
                      </label>
                    </div>
                    <div style={{ width: "100px", paddingTop: "8px" }}>
                      <Qty
                        type={"number"}
                        label={"Qty"}
                        onChange={e => {
                          dispatch(
                            updateCartItems({
                              index,
                              value: {
                                ...item,
                                product_quantity: e.target.value,
                              },
                            })
                          )
                        }}
                        onBlur={e => {
                          dispatch(
                            updateCartItems({
                              index,
                              value: {
                                ...item,
                                product_quantity:
                                  e.target.value < item.moq
                                    ? item.moq
                                    : e.target.value,
                              },
                            })
                          )
                          dispatch(
                            updateQtyDate({
                              body: {
                                cart_item_token: item.cart_item_token,
                                quantity:
                                  e.target.value < item.moq
                                    ? item.moq
                                    : e.target.value,
                                requested_date: item.requested_date,
                              },
                            })
                          )
                        }}
                        value={item.product_quantity}
                      />
                    </div>
                    <div style={{ width: "fit-content" }}>
                      <CustomDatePicker
                        sx={{
                          borderRadius: "5px",
                          width: "154px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",
                            width: "154px",
                          },
                        }}
                        disabled={item.stock_status === 1 ? false : true}
                        label={"Need Date"}
                        format="DD-MM-YYYY"
                        minDate={dayjs(currentDate())}
                        value={dayjs(item.requested_date)}
                        onChange={value => {
                          const isValid = validateDate(index, value)
                          if (isValid) {
                            dispatch(
                              updateCartItems({
                                index,
                                value: {
                                  ...item,
                                  requested_date:
                                    dayjs(value).format("YYYY-MM-DD"),
                                },
                              })
                            )
                            dispatch(
                              updateQtyDate({
                                body: {
                                  cart_item_token: item.cart_item_token,
                                  quantity: item.product_quantity,
                                  requested_date:
                                    dayjs(value).format("YYYY-MM-DD"),
                                },
                              })
                            )
                          }
                        }}
                      />
                      {/* Show error message if the date is invalid */}
                      {dateError[index] && (
                        <span className={classes.error}>
                          Please select a valid date.
                        </span>
                      )}
                    </div>
                    <label className={classes.price}>
                      <span className="count" style={{ fontSize: "24px" }}>
                        ₹ {item.total_item_rate.toLocaleString("en-IN")}
                      </span>
                      <span
                        className="line_through_price"
                        style={{ fontSize: "16px" }}
                      >
                        ₹ {item.total_mrp.toLocaleString("en-IN")}
                      </span>
                    </label>
                  </div>

                  <span
                    className="cursor iconaddcart"
                    aria-label="Remove from cart"
                    onClick={() => removeCartIteme(item.cart_item_token)}
                  >
                    <img
                      src={remove}
                      alt="Remove from cart"
                      style={{ width: "20px" }}
                    />
                  </span>
                </div>
              </div>
            ))}
            <div className={classes.card_box_total}>
              <div
                className={classes.total_card}
                style={{ paddingBottom: "3rem" }}
              >
                <div className={classes.tax}>
                  <div className={classes.flex}>
                    <p>Order Total Value</p>
                    <p>
                      ₹{" "}
                      {cart?.total_item_amount
                        ? cart?.total_item_amount.toLocaleString("en-IN")
                        : 0}
                    </p>
                  </div>
                  <div className={classes.flex}>
                    <p>Total Tax ({cart?.total_tax_percentage || 0}%)</p>
                    <p>
                      ₹{" "}
                      {cart?.total_tax_amount
                        ? cart?.total_tax_amount.toLocaleString("en-IN")
                        : 0}
                    </p>
                  </div>
                </div>
                <div className={classes.total}>
                  <div className={classes.flex}>
                    <p>Total Amount</p>
                    <p>
                      ₹{" "}
                      {cart?.total_amount
                        ? cart?.total_amount.toLocaleString("en-IN")
                        : 0}
                    </p>
                  </div>
                  <CustomButton
                    variant="contained"
                    customColor="white"
                    customBgColor="#32349b"
                    custmstyle={{ padding: "7px 7px", width: "100%" }}
                    onClick={handlerContinue}
                  >
                    Continue
                  </CustomButton>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <ToastContainer />
    </div>
  )
}

export default Summary
