import * as React from "react"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material"
import TickIcon from "../svgComponents/TickIcon"
import PendingIcon from "../svgComponents/pendingIcon"
import { useNavigate } from "react-router-dom"

export default function OrderCard(props) {
  const {
    status = "Delivered",
    etd = "05-Sep-2024",
    customStyle,
    data,
    id,
    items,
    rate,
    type,
    order_date,
  } = props
  const tickColour =
    status === "Order Acknowledged"
      ? "var(--status-magenta)"
      : status === "Dispatched"
      ? "var(--status-cyan)"
      : status === "Partially Dispatched"
      ? "var(--status-greenbrown)"
      : "var(--status-green)"

  const navigator = useNavigate()

  return (
    <>
      {/* <UICard>xczsc</UICard> */}
      <Card
        sx={{
          boxShadow: "var(--box-shadow)",
          borderRadius: "8px",
          border: "1px solid rgba(242, 242, 242, 1)",
          opacity: "1",
          backgroundColor: "rgba(255, 255, 255, 1)",
          ...customStyle,
        }}
      >
        <CardContent sx={{ padding: "14px" }}>
          <Typography
            variant="h6"
            sx={{ fontSize: 16, fontFamily: "var(--font-semibold)" }}
            component="div"
          >
            {id}
          </Typography>
          <Typography
            gutterBottom
            sx={{
              color: "text.secondary",
              fontSize: 13,
              fontFamily: "var(--font-regular)",
              letterSpacing: "-0.18571428954601288",
              marginBottom: "0.5rem",
            }}
          >
            Ordered on {order_date}
          </Typography>

          <Typography
            sx={{
              color: "text.secondary",
              fontFamily: "var(--font-semibold)",
              fontSize: 12,
              transform: "uppercase",
            }}
          >
            SUMMARY
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "var(--font-regular)",
              fontSize: 16,
              mb: 1.6,
              letterSpacing: "-0.22857142984867096",
            }}
          >
            <span
              style={{
                color: "#000000",
                fontWeight: "600",
                fontFamily: "var(--font-semibold)",
              }}
            >
              {items} items
            </span>{" "}
            <br /> {"worth"}{" "}
            <span
              style={{
                color: "#000000",
                fontWeight: "600",
                fontFamily: "var(--font-semibold)",
              }}
            >
              Rs.{rate}
            </span>
            {/* {props.status === "Delivered" ? " will be " : " was "}
            {"delivered on"}
            <br />
            {etd} */}
          </Typography>
          <Typography
            sx={{ borderBottom: "1px solid var(--light-outline)" }}
            component="div"
          ></Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "14px",
            paddingTop: "0px",
          }}
        >
          <Typography
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            component="div"
          >
            {status === "Pending" ? (
              <PendingIcon />
            ) : (
              <TickIcon colour={tickColour} />
            )}
            <Typography
              sx={{
                color: "var(--text-color-dark)",
                fontFamily: "var(--font-semibold)",
                fontSize: 12,
              }}
            >
              {status}
            </Typography>
          </Typography>
          <Button
            size="small"
            onClick={() => {
              if (type === "recent_order" && status === "Delivered") {
                if (props?.cta) {
                  props?.cta()
                }
              } else {
                navigator(`/view_my_order/${data?.token}`)
              }
            }}
            sx={{
              color: "var(--primary-blue)",
              fontFamily: "var(--font-semibold)",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            {status === "Delivered" ? "Repeat " : "Track "} Order
          </Button>
        </CardActions>
      </Card>
    </>
  )
}
