import { Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import CustomButton from "../components/UI/Button/Button"
import { setSteperVlue } from "../services/storeSlice/shopNowSlice"
import { useNavigate } from "react-router-dom"
import acknowlegment from "../assets/image/svg/download_2.svg"
import classes from "./OrderSummary.module.css"
import { jsPDF } from "jspdf"
import * as XLSX from "xlsx"

const OrderSummary = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const arr = ["", "", ""]
  const summaryData = useSelector(state => state.summaryData)
  const summaryData1 = summaryData.updatesummarydata

  const downloadPDFOrder = () => {
    const doc = new jsPDF("p", "mm", "a4")

    // Set font styles and sizes
    doc.setFont("helvetica", "normal") // Helvetica supports ₹ symbol

    // Header section
    doc.setFontSize(20)
    doc.setTextColor(55, 55, 55) // Dark color for headings

    // Company name/logo
    doc.text("Addison & Co. Ltd", 70, 20)

    // Set line color to gray
    doc.setDrawColor(169, 169, 169) // Gray color for lines
    doc.line(10, 25, 200, 25) // Horizontal line separating header

    // Adjust Y-coordinate for subsequent lines
    let yPosition = 35 // Initial Y position for content

    // First line: "Order Details:"
    doc.setFontSize(20)
    doc.text("Order Details:", 10, yPosition)

    // Second line: "Martha Pearson"
    yPosition += 10 // Move Y-coordinate down
    doc.setFontSize(24) // Adjust font size for name
    doc.text(`${localStorage.getItem("contactName")}`, 10, yPosition)

    // Third line: Contact details
    yPosition += 10 // Move Y-coordinate down
    doc.setFontSize(12) // Adjust font size for contact info
    doc.text(
      ` ${
        summaryData1?.shipping_address?.mobile_number
      } | ${localStorage.getItem("userEmail")}`,
      10,
      yPosition
    )

    // Column structure for Order, Shipping, and Billing details
    const col1 = 10,
      col2 = 70,
      col3 = 140
    yPosition += 15 // Increase Y-position for the next section

    // Change highlight color for headings to dark color
    doc.setFontSize(14)
    doc.setTextColor(55, 55, 55) // Dark color for headings
    doc.text("Order Details:", col1, yPosition)
    doc.text("Shipping Address:", col2, yPosition)
    doc.text("Billing Address :", col3, yPosition)

    // Column Values
    doc.setFontSize(12)
    doc.setTextColor(128, 128, 128) // Gray color for normal text

    yPosition += 7 // Adjust Y-position for the order details

    // Dynamic Order Details
    doc.text(`Order ID: ${summaryData1?.order_id}`, col1, yPosition)
    doc.text(`Order Date: ${summaryData1?.created_at}`, col1, yPosition + 6)
    doc.text(
      `Delivery Date: ${summaryData1?.estimate_delivery_date}`,
      col1,
      yPosition + 12
    )

    // Shipping Address
    doc.text(
      `${summaryData1?.shipping_address?.address_line_1},\n${summaryData1?.shipping_address?.address_line_2},\n${summaryData1?.shipping_address?.city}, ${summaryData1?.shipping_address?.state} ${summaryData1?.shipping_address?.pincode},\n${summaryData1?.shipping_address?.country}.`,
      col2,
      yPosition
    )

    // Billing Address
    doc.text(
      `${summaryData1?.billing_address?.address_line_1},\n${summaryData1?.billing_address?.address_line_2},\n${summaryData1?.billing_address?.city}, ${summaryData1?.billing_address?.state} ${summaryData1?.billing_address?.pincode},\n${summaryData1?.billing_address?.country}.`,
      col3,
      yPosition
    )

    // Separator line
    yPosition += 30 // Increase Y-coordinate for separator line
    doc.line(10, yPosition, 200, yPosition) // Gray line (since drawColor is already set to gray)

    // Table Header
    yPosition += 10 // Adjust Y-position for table header
    doc.setFontSize(12)
    doc.setTextColor(55, 55, 55) // Dark color for table headers
    doc.text("DESCRIPTION", 10, yPosition)
    doc.text("QTY", 105, yPosition)
    doc.text("UNIT PRICE", 120, yPosition)
    doc.text("DATE", 150, yPosition)
    doc.text("AMOUNT", 180, yPosition)

    // Dynamic Table Rows
    yPosition += 10 // Start the first row after the header
    summaryData1?.products?.forEach(item => {
      doc.setTextColor(128, 128, 128) // Gray for table rows

      // Split product name into multiple lines if it exceeds 32 characters
      const productNameLines = doc.splitTextToSize(item.product_name, 90) // 90 is the width you want to allow for the product name

      // Draw the product name lines
      productNameLines.forEach((line, index) => {
        doc.text(line, 10, yPosition + index * 10) // Adjust yPosition for each line
      })

      // Draw other details in the same row
      doc.text(`${item.quantity}`, 105, yPosition)
      doc.text(
        `${Number(item.product_rate_mrp).toLocaleString("en-IN")}`,
        120,
        yPosition
      )
      doc.text(`${item.requested_date}`, 150, yPosition)
      doc.text(
        `${Number(item.product_total_amount).toLocaleString("en-IN")}`,
        180,
        yPosition
      )

      // Move the yPosition down for the next row
      yPosition += Math.max(10, productNameLines.length * 10) // Move down according to the number of lines
    })

    // Summary Section
    yPosition += 10
    doc.setTextColor(55, 55, 55) // Dark color for summary labels
    doc.text("Order Value", 150, yPosition)
    doc.setTextColor(128, 128, 128) // Gray for summary values
    doc.text(
      `${Number(summaryData1?.order_value).toLocaleString("en-IN")}`,
      180,
      yPosition
    )

    yPosition += 10
    doc.setTextColor(55, 55, 55)
    doc.text("Total Tax", 150, yPosition)
    doc.setTextColor(128, 128, 128)
    doc.text(
      `${Number(summaryData1?.order_tax_amount).toLocaleString("en-IN")}`,
      180,
      yPosition
    )

    yPosition += 10
    doc.setTextColor(55, 55, 55)
    doc.text("Grand Total", 150, yPosition)
    doc.setTextColor(128, 128, 128)
    doc.text(
      `${Number(summaryData1?.item_total_value).toLocaleString("en-IN")}`,
      180,
      yPosition
    )

    // Save PDF
    doc.save("Order_Summary.pdf")
  }

  return (
    <div style={{ paddingTop: "24px", paddingBottom: "100px" }}>
      <Stack direction={"row"} gap={"20px"}>
        <Stack style={{ width: "100%" }} direction={"column"} gap={"24px"}>
          {/* div order */}
          <Stack
            direction={"row"}
            gap={"16px"}
            style={{
              borderRadius: "8px",
              borderColor: "#E8E8E8",
              backgroundColor: "#FFFFFF",
              alignItems: "center",
              padding: "30px 24px",
            }}
          >
            <SuccessSvg />
            <Stack>
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                Order Placed Successfully!
              </Typography>
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                Your order was successful and your purchase order ID is
                <span style={{ color: "#050505" }}>
                  {" "}
                  {summaryData1?.order_id}.
                </span>
              </Typography>
            </Stack>
          </Stack>
          {/* div detail */}
          <Stack
            direction={"column"}
            style={{
              borderRadius: "8px",
              borderColor: "#E8E8E8",
              backgroundColor: "#FFFFFF",
              padding: "24px",
              paddingBottom: "42px",
              alignContent: "start",
            }}
            gap={"8px"}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {summaryData1?.order_id}
              </Typography>
              <div
                className={classes.downlode_compo}
                onClick={() => downloadPDFOrder()}
              >
                <img src={acknowlegment} alt="acknowlegment" />
                <p>Download Order Details</p>
              </div>
            </Stack>
            <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
              PO Date:{" "}
              <span style={{ fontWeight: "600" }}>
                {" "}
                {summaryData1?.created_at}
              </span>
            </Typography>
            <Stack direction={"row"}>
              <Stack direction={"column"} gap={"24px"} style={{ width: "50%" }}>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Shipping Address
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {summaryData1?.shipping_address?.address_line_1},<br />
                    {summaryData1?.shipping_address?.address_line_2},<br />
                    {summaryData1?.shipping_address?.city},{" "}
                    {summaryData1?.shipping_address?.state}{" "}
                    {summaryData1?.shipping_address?.pincode},{" "}
                    {summaryData1?.shipping_address?.country}.
                  </Typography>
                </Stack>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  + {summaryData1?.shipping_address?.country_code} -{" "}
                  {summaryData1?.shipping_address?.mobile_number}
                </Typography>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Estimated Delivered Date
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {summaryData1?.estimate_delivery_date}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"column"} gap={"24px"} style={{ width: "50%" }}>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Billing Address
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {summaryData1?.billing_address?.address_line_1},<br />
                    {summaryData1?.billing_address?.address_line_2},<br />
                    {summaryData1?.billing_address?.city},{" "}
                    {summaryData1?.billing_address?.state}{" "}
                    {summaryData1?.billing_address?.pincode},{" "}
                    {summaryData1?.billing_address?.country}.
                  </Typography>
                </Stack>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  + {summaryData1?.billing_address?.country_code} -{" "}
                  {summaryData1?.billing_address?.mobile_number}
                </Typography>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Estimated Delivered Date
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {summaryData1?.estimate_delivery_date}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {/* div summary */}
          <Stack
            direction={"column"}
            style={{
              borderRadius: "8px",
              borderColor: "#E8E8E8",
              backgroundColor: "#FFFFFF",
              padding: "24px",
              paddingBottom: "42px",
              alignContent: "start",
            }}
          >
            <Stack
              style={{
                borderBottom: "1px solid #E8E8E8",
                paddingBottom: "20px",
              }}
              gap={"10px"}
              direction={"column"}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: "2px",
                }}
              >
                Order Summary
              </Typography>
              <Stack
                direction={"row"}
                style={{ justifyContent: "space-between" }}
              >
                <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                  Order Value
                </Typography>
                <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                  ₹{" "}
                  {summaryData1?.order_value
                    ? Number(summaryData1?.order_value).toLocaleString("en-IN")
                    : 0}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                style={{ justifyContent: "space-between" }}
              >
                <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                  Total Tax ({summaryData1?.tax_percentage}%)
                </Typography>
                <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                  ₹{" "}
                  {summaryData1?.order_tax_amount
                    ? Number(summaryData1?.order_tax_amount).toLocaleString(
                        "en-IN"
                      )
                    : 0}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              style={{ justifyContent: "space-between", marginTop: "24px" }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                Total Amount
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#65A547",
                }}
              >
                ₹{" "}
                {summaryData1?.item_total_value
                  ? Number(summaryData1?.item_total_value).toLocaleString(
                      "en-IN"
                    )
                  : 0}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          style={{
            width: "100%",
            borderRadius: "8px",
            borderColor: "#E8E8E8",
            backgroundColor: "#FFFFFF",
            padding: "24px",
            maxHeight: "747px",
            overflow: "hidden",
            overflowY: "scroll",
            alignSelf: "start",
          }}
        >
          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
            Product Details
            {summaryData1?.products?.map(item => (
              <Stack
                direction={"row"}
                style={{
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E8E8E8",
                  paddingBlock: "24px",
                }}
              >
                <Stack direction={"column"} gap={"10px"}>
                  <Stack>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000000",
                      }}
                    >
                      {item?.product_code}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {item?.product_name}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={"8px"}>
                    <Typography
                      variant="span"
                      sx={{
                        backgroundColor: "#EFEFEF",
                        borderRadius: "4px",
                        padding: "4px 6px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#000000",
                      }}
                    >
                      Material: {item?.material}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        backgroundColor: "#EFEFEF",
                        borderRadius: "4px",
                        padding: "4px 6px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#000000",
                      }}
                    >
                      Finish: {item?.coating} COATING
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    gap={"45px"}
                    style={{ marginTop: "6px" }}
                  >
                    <Typography
                      style={{
                        color: "#68686A",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Qty:{" "}
                      <span style={{ color: "#000000" }}>{item?.quantity}</span>{" "}
                      pcs
                    </Typography>
                    <Typography
                      style={{
                        color: "#68686A",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Needed Date:{" "}
                      <span style={{ color: "#000000" }}>
                        {item?.requested_date}
                      </span>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"column"} style={{ alignItems: "end" }}>
                  <Typography
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    ₹{" "}
                    {item?.product_total_amount
                      ? Number(item?.product_total_amount).toLocaleString(
                          "en-IN"
                        )
                      : 0}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#979797",
                      textDecoration: "line-through",
                    }}
                  >
                    ₹{" "}
                    {item?.product_rate_mrp
                      ? Number(item?.product_rate_mrp).toLocaleString("en-IN")
                      : 0}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Typography>
        </Stack>
      </Stack>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CustomButton
          variant="contained"
          customColor="white"
          customBgColor="#32349b"
          custmstyle={{
            padding: "10px 7px",
            width: "400px",
            marginTop: "20px",
            borderRadius: "8px",
          }}
          onClick={() => {
            navigate("/myorders")
            dispatch(setSteperVlue(0))
            localStorage.removeItem("shop_status")
          }}
        >
          Go to My Orders
        </CustomButton>
      </div>
    </div>
  )
}
export default OrderSummary

const SuccessSvg = () => {
  return (
    <svg
      width="58px"
      height="58px"
      viewBox="0 0 120 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>web/md/svg/success popup</title>
      <g
        id="web/md/svg/success-popup"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Group"
          transform="translate(10, 10)"
          fill="#65A547"
          fill-rule="nonzero"
        >
          <path
            d="M50,0 C22.5,0 0,22.5 0,50 C0,77.5 22.5,100 50,100 C77.5,100 100,77.5 100,50 C100,22.5 77.5,0 50,0 L50,0 Z M40,75 L15,50 L22,43 L40,61 L78,23 L85,30 L40,75 L40,75 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}
