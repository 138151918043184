import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import classes from './OrderDetailsTable.module.css'
import { Button, FormControl, InputLabel, MenuItem, Select, SvgIcon, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import downloadIcon from '../../../assets/image/svg/download.svg'
import searchIcon from '../../../assets/image/svg/order_search.svg'
import useApiCall from '../../../hooks/useApiCall'
import secureLocalStorage from 'react-secure-storage';
import ViewModal from '../../../components/UI/viewModal/ViewModal';
import FromToDatePicker from '../../../components/UI/DatePicker/FromToDatePicker';
import dayjs from 'dayjs';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: 'sno',
    numeric: false,
    disablePadding: true,
    label: 'S.No',
  },
  {
    id: 'product_id',
    numeric: true,
    disablePadding: false,
    label: 'Product ID',
  },
  {
    id: 'product_name',
    numeric: true,
    disablePadding: false,
    label: 'Product Name',
  },
  {
    id: 'needed_date',
    numeric: true,
    disablePadding: false,
    label: 'Needed Date',
  },
  {
    id: 'qty',
    numeric: true,
    disablePadding: false,
    label: 'Qty',
  },
  {
    id: 'change_status',
    numeric: true,
    disablePadding: false,
    label: 'Change Status',
  },
  {
    id: 'estimate_delivery_date',
    numeric: true,
    disablePadding: false,
    label: 'Estimate Delivery Date',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,nonDeliveryRows} =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            indeterminate={numSelected > 0 && numSelected < nonDeliveryRows}
            // checked={rowCount > 0 && numSelected === rowCount}
            checked={nonDeliveryRows > 0 && numSelected === nonDeliveryRows}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            sx={{
              width: '30px',
              height: '30px',
              '&.Mui-checked': {
                color: '#C4C4C4',
              },
              '&.MuiCheckbox-root': {
                color: '#C4C4C4',
              },
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <div className='line' style={{ height: '14px', backgroundColor: '#C4C4C4', width: "1px", marginRight: '7.92px' }}></div>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedOrdersTable({ data, statusData,orderToken }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [visibleRows, setVisibleRows] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [statusUpdateData, setStatusUpdateData] = React.useState({});
  const [eddModal, setEddModal] = React.useState(false);
  const [eddDate, setEddDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  const { isLoading: isStatusUpdating, success: statusUpdateSuccess, error: statusUpdateError, code: statusUpdateStatusCode, errorData: statusUpdateErrorData, sendRequest: statusUpdate } = useApiCall();

  const headCells = [
    {
      id: 'sno',
      numeric: false,
      disablePadding: true,
      label: 'S.No',
    },
    {
      id: 'product_id',
      numeric: true,
      disablePadding: false,
      label: 'Product ID',
    },
    {
      id: 'product_name',
      numeric: true,
      disablePadding: false,
      label: 'Product Name',
    },
    {
      id: 'needed_date',
      numeric: true,
      disablePadding: false,
      label: 'Needed Date',
    },
    {
      id: 'qty',
      numeric: true,
      disablePadding: false,
      label: 'Qty',
    },
    {
      id: 'estimate_delivery_date',
      numeric: true,
      disablePadding: false,
      label: 'Estimate Delivery Date',
    },
    {
      id: 'price',
      numeric: true,
      disablePadding: false,
      label: 'Price',
    }
  ];
  function createData(sno, token, product_id, product_name, needed_date, qty, estimate_delivery_date, price,status_code,status) {
    return {
      sno, token, product_id, product_name, needed_date, qty, estimate_delivery_date, price,status_code,status
    };
  }

  React.useEffect(() => {
    const rowsData = []
    console.log("data", data);
    if (data) {
      data?.map((item, index) => {
        rowsData.push(createData(item.si_no, item.token, item.product_code, item.product_name, item.needed_date, item.quantity, item.delivered_date, item.total_amount,item.status_code,item.status))
      })
    }
    setRows(rowsData)
  }, [data])

  React.useEffect(() => {
    const filteredRows = rows.filter(row => {
      return (
        row.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.product_id.toString().includes(searchTerm) ||
        row.needed_date.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.qty.toString().includes(searchTerm) ||
        row.estimate_delivery_date.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.price.toString().includes(searchTerm)
      );
    });

    const sortedRows = filteredRows.sort(getComparator(order, orderBy));
    const slicedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setVisibleRows(slicedRows);
  }, [rows, order, orderBy, page, rowsPerPage, searchTerm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.token);
      const newSelected = rows.map((n) => {if(n.status_code != 4) return n.token});

      setSelected(newSelected);
      return;
    }
    setSelected([]);

  };

  const handleClick = (event, id) => {

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const statusCard = (status) => {
    return (
      <div className={classes.status_card_container}>
        {
          status === "Pending" ?
            <p className={classes.pending}>{status}</p>
            :
            status === "Partially Dispatched" ?
              <p className={classes.partially_dispatched}>{status}</p>
              :
            status === "Dispatched" ?
              <p className={classes.dispatched}>{status}</p>
              :
              status === "Order Acknowledged" ?
                <p className={classes.order_acknowledged}>{status}</p>
                :
                status === "Delivered" &&
                <p className={classes.delivered}>{status}</p>
        }
      </div>
    )
  }

  // Handle status change for a specific product
  const onStatusUpdateSuccess = (resp,tokenArray,statusCode)=>{
    let updatedRows = rows;
    tokenArray.forEach((token)=>{
      let singleRowUpdate = updatedRows.map((item)=>
        item.token === token ? {...item,status_code:statusCode,status:statusData.find((item)=>Number(item.id) === Number(statusCode)).name} : item
      )
      updatedRows = [...singleRowUpdate]
    })
   
    setRows(updatedRows);
    setStatusUpdateData({});
    if(selected.length > 0){
      setSelected([])
    }
    if(eddModal)setEddModal(false)
      setEddDate(dayjs().format("YYYY-MM-DD"));
  }

  const handleUpdateStatusApi = (edd="",tokens=null,code=null)=>{
    let prodTokenArray = tokens !== null ? tokens: statusUpdateData.prod_tok;
    
    let statusCode = code !== null ? code : statusUpdateData.status;

    statusUpdate({
      url: "marketing/product-order-status-update",
      method: 'POST',
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem('marketingBearerToken')}`
      },
      body: {
        order_token:orderToken,
        product_tokens:prodTokenArray,
        status:statusCode,//"3","4"
        estimate_delivery_date:edd ,
      }
    }, (response) => {
      onStatusUpdateSuccess(response,prodTokenArray,statusCode)

    })
  }
  const handleChangeStatus = (prod_tok, item,type="single") => {
    if(item.target.value < 3) return
    
    setStatusUpdateData({
      prod_tok:type === "single" ? [prod_tok] : selected,
      status:item.target.value 
    })
    let tokenArray = type === "single" ? [prod_tok] : selected

    if(item.target.value === '3'){
      // handleUpdateStatusApi(eddDate)
      setEddModal(true)
    }else if(item.target.value === '4'){
      handleUpdateStatusApi('',tokenArray,item.target.value );
    }
  };

  const handleEddDateChange = value => {
    const newFromDate = dayjs(value).format("YYYY-MM-DD")
    setEddDate(newFromDate)
}

  const RenderModalContent = () => (
    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}} mt={5}>
       <FromToDatePicker
            label={"EDD"}
            format="DD-MM-YYYY"
            value={dayjs(eddDate) || null}
            minDate={dayjs(dayjs().format("YYYY-MM-DD"))}
            height={"50px"}
            onChange={handleEddDateChange}
        />
    </Box>
  )

  const RenderCta = () => {
    const cancelCta = () => {
      setStatusUpdateData({});
      setEddModal(false)

    }

    const onSubmitCta = () => {
      handleUpdateStatusApi(eddDate);

    }
    return (
      <>
        <Button sx={{ color: "grey", fontFamily: "var(--font-semibold)" }} onClick={cancelCta} className={classes.button_style}>Cancel</Button>
        <Button sx={{ fontFamily: "var(--font-semibold)" }} onClick={onSubmitCta} className={classes.button_style}>Submit</Button>

      </>

    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <div className={classes.table_header_container}>
          <p className={classes.table_header_title}>Product Details</p>
          <div className={classes.table_header_total_search}>
            <p className={classes.table_header_sub_title}>{data?.length} Items</p>
            <div className={classes.icons_container}>
              {selected.length > 0 && (
                
                <div className={classes.form_element}>
                            <FormControl >
                              <InputLabel sx={{ paddingBottom: "2px" }} id="demo-simple-select-label">Choose Status</InputLabel>
                              <Select
                                size="small"
                                labelId={`select-label-${"bulk-update"}`}
                                id={`select-${"bulk-update"}`}

                                value={"Select"}
                                // disabled={row.status_code < 1}
                                label="Choose Status"
                                onChange={(item) => handleChangeStatus("", item,"multiple")}
                                sx={{
                                  marginRight:"10px",
                                  width: "170px",
                                  borderRadius: '8px', // Set the desired border radius
                                  '& .MuiSelect-select': {
                                    borderRadius: '8px', // Ensure the inner select has the same border radius
                                    fontFamily: 'Proxima-Regular', // Change to your desired font family for the value
                                  },
                                  '& .MuiSelect-icon': {
                                    fontFamily: 'Proxima-Regular', // Change font family for the dropdown icon if needed
                                  },
                                }}
                              >
                                <MenuItem value={"Select"}>Select</MenuItem>
                                {statusData?.map((item, index) => {
                                  // Determine if the item should be disabled
                                  {/* const isDisabled = Number(item.id) <= Number(row.status_code) || Number(item.id) < 3; // Disable if "3" is selected */}
                                  if (index > 2) {

                                    return (
                                      <MenuItem key={index} value={item.id} >
                                        {item.name}
                                      </MenuItem>
                                    );
                                  }

                                })}
                              </Select>
                            </FormControl>
                          </div>
              )}

              <TextField
                id="outlined-basic"
                label="Search"
                size="small"
                variant="outlined"
                value={searchTerm}
                sx={{ marginRight: 1 }}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              <img src={searchIcon} width={24} height={24} />
            </div>
          </div>
        </div>
        <TableContainer>
          {
            visibleRows?.length > 0 ?
              <Table
                sx={{ minWidth: 750, marginRight: "28px", marginLeft: "28px" }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  nonDeliveryRows={rows.filter((item)=>item.status_code != 4).length}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    const isItemSelected = selected.includes(row.token);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell
                          align="left"
                          padding="checkbox"
                          sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}
                        >
                          <Checkbox
                            color="primary"
                            onClick={(event) => handleClick(event, row.token)} // Use token as identifier
                            checked={selected.includes(row.token)} // Check if token is in selected array
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            disabled={row?.status_code === 4}
                            sx={{
                              width: '30px',
                              height: '30px',
                              '&.Mui-checked': {
                                color: '#C4C4C4',
                              },
                              '&.MuiCheckbox-root': {
                                color: '#C4C4C4',
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}
                        >
                          {row.sno}
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.product_id}</TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.product_name}</TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.needed_date}</TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.qty}</TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>
                          <div className={classes.form_element}>
                            <FormControl >
                              <InputLabel sx={{ paddingBottom: "2px" }} id="demo-simple-select-label">Choose Status</InputLabel>
                              <Select
                                size="small"
                                labelId={`select-label-${row.token}`}
                                id={`select-${row.token}`}

                                value={row.status_code}
                                disabled={row.status_code < 1 || row.status_code === 4}
                                label="Choose Status"
                                onChange={(item) => handleChangeStatus(row.token, item)}
                                sx={{
                                  width: "170px",
                                  borderRadius: '8px', // Set the desired border radius
                                  '& .MuiSelect-select': {
                                    borderRadius: '8px', // Ensure the inner select has the same border radius
                                    fontFamily: 'Proxima-Regular', // Change to your desired font family for the value
                                  },
                                  '& .MuiSelect-icon': {
                                    fontFamily: 'Proxima-Regular', // Change font family for the dropdown icon if needed
                                  },
                                }}
                              >
                                <MenuItem value={"Select"}>Select</MenuItem>
                                {statusData?.map((item, index) => {
                                  // Determine if the item should be disabled
                                  const isDisabled = Number(item.id) <= Number(row.status_code) || Number(item.id) < 3; // Disable if "3" is selected
                                  if (index !== 2) {

                                    return (
                                      <MenuItem key={index} value={item.id} disabled={isDisabled}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  }

                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.estimate_delivery_date}</TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.price}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              :
              <p className={classes.no_data}>No orders found</p>
          }
        </TableContainer>
        {
          visibleRows?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )
        }
      </Paper>
      <ViewModal
        title={"Estimated Delivery Date"}
        modalOpen={eddModal}
        modalContent={RenderModalContent}
        trigger={setEddModal}
        customStyle={{width:400}}
        actionButtons={RenderCta}
      />
    </Box>
  );
}
