import React, { useEffect, useState } from "react"
import classes from "./MyOrdersMain.module.css"
import OngoingOrders from "./OngoingOrders"
import CustomTable from "../../components/UI/Table/TablePage"
import useApiHttp from "../../hooks/ues-http"
import SelectSmall from "../../components/UI/Dropdown/Select"
import SearchExpand from "../../components/UI/SearchExpand/SearchExpand"
import downlodeImge from "../../assets/image/svg/download.svg"
import reOrderIcon from "../../assets/image/svg/reorder.svg"
import dayjs from "dayjs"
import FromToDatePicker from "../../components/UI/DatePicker/FromToDatePicker"
import { currentDate, handleFileDownload } from "../../utils/helper"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import DropDownFile from "../../components/UI/DropDownFile/DropDownFile"
import axios from "axios"
import secureLocalStorage from "react-secure-storage"

const MyOrdersMain = () => {
  const navigator = useNavigate()
  const [orderHistoryData, setOrderHistoryData] = useState([])

  console.log("orderHistoryData", orderHistoryData)

  const [sortDirectionData, setSortDirectionData] = useState("DESC")
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [column, setColumn] = useState(0)
  const [total, setTotal] = useState([])
  const [showTotal, setShowTotal] = useState(0)
  const [searchVlue, setSearchVlue] = useState("")

  const [fromDate, setFromDate] = useState("") // Set initial from date
  const [toDate, setToDate] = useState("") // Set initial to date

  const {
    isLoading: OrderHistoryLoading,
    success: OrderHistorySucces,
    error: OrderHistoryError,
    sendRequest: OrderHistoryRequest,
  } = useApiHttp()
  const {
    isLoading: pdfLoading,
    success: pdfSucces,
    error: pdfError,
    sendRequest: pdfRequest,
  } = useApiHttp()

  const {
    isLoading: repeadOrderLoading,
    success: repeadOrderSucces,
    error: repeadOrderError,
    sendRequest: repeadOrderRequest,
  } = useApiHttp()

  const repeadOrderRequestHandle = token => {
    repeadOrderRequest(
      {
        url: `dealer/shop-now/repeat-order`,
        method: "POST",
        body: {
          order_token: token,
        },
      },
      data => {
        if (data.status_code === 200) {
          setTimeout(() => {
            navigator("/shopnow")
          }, 1000)
        }
      }
    )
  }

  useEffect(() => {
    if (repeadOrderSucces !== "") {
      toast.success(repeadOrderSucces, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (repeadOrderError !== "") {
      toast.error(repeadOrderError, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [repeadOrderSucces, repeadOrderError])

  const OrderHistoryHandleSelect = () => {
    OrderHistoryRequest(
      {
        url: `dealer/my-order/order-history`,
        method: "POST",
        body: {
          from_date: fromDate,
          to_date: toDate,
          search_value: searchVlue,
          page: page,
          per_page: perPage,
          sort_dir: sortDirectionData,
          sort_column: column,
        },
      },
      data => {
        setOrderHistoryData(data.data.aaData)
        setTotal(data.data.iTotalRecords)
        setShowTotal(data.data.iTotalDisplayRecords)
        console.log("console ====> ", data)
      }
    )
  }

  useEffect(() => {
    OrderHistoryHandleSelect()
  }, [page, perPage, column, sortDirectionData, toDate, fromDate, searchVlue])

  const pdfHandleSelect = fileType => {
    const AdminbearerToken = secureLocalStorage.getItem("dealerBearerToken")

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_API_URL}/dealer/my-order/order-history/download`,
      data: {
        type: fileType,
        from_date: fromDate,
        to_date: toDate,
        search_value: searchVlue,
      },
      headers: {
        Authorization: `Bearer ${AdminbearerToken}`,
      },
      responseType: "blob", // Important for binary data
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url

        // Extract the filename from the response headers (optional)
        const contentDisposition = response.headers["content-disposition"]
        let fileName = `downloaded_file.${fileType}` // default file name with correct extension

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch && fileNameMatch.length === 2) {
            fileName = fileNameMatch[1]
          }
        } else {
          // Fallback to using the fileType for naming the file
          fileName = `order_history.${fileType}`
        }

        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
        link.remove() // cleanup
      })
      .catch(error => {
        console.error("Error downloading the file:", error)
      })
  }

  const orderHistory = [
    {
      name: "S.No",
      selector: row => row.slno,
      sortable: false,
      sortField: 0,
    },
    {
      name: "order_id",
      selector: row => (
        <>
          <p
            className="action_text action_text_pointer"
            onClick={() => navigator(`/view_my_order/${row.token}`)}
          >
            {row.order_id}
          </p>
        </>
      ),
      sortable: true,
      sortField: 1,
    },
    {
      name: "No.of Item",
      selector: row => row.no_of_item,
      sortable: true,
      sortField: 2,
    },

    {
      name: "Total Cost",
      selector: row => row.total_cost,
      sortable: true,
      sortField: 3,
    },
    {
      name: "Ordered Date",
      selector: row => row.order_date,
      sortable: true,
      sortField: 4,
    },
    {
      name: "Delivered Date",
      selector: row => row.deliver_date,
      sortable: true,
      sortField: 5,
    },
    {
      name: "",
      cell: row => (
        <>
          <p
            className="action_text action_text_pointer flex"
            onClick={() => repeadOrderRequestHandle(row.token)}
          >
            <img src={reOrderIcon} alt="reOrderIcon" />
            Repeat Order
          </p>
        </>
      ),
      sortable: true,
      sortField: 6,
    },
  ]

  const handleSort = async (column, sortDirection) => {
    setSortDirectionData(sortDirection || "desc")
    setColumn(column.sortField || 0)
    console.log("column", sortDirection, column.sortField)
  }

  const handlePageChange = page => {
    setPage(page || 1)
    {
      page > 1
        ? localStorage.setItem("page", parseInt(page))
        : console.log(page)
    }
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage || 10)
    {
      newPerPage > 10
        ? localStorage.setItem("newPerPage", parseInt(newPerPage))
        : console.log(newPerPage)
    }
  }
  const clearSate = () => {
    setFromDate("")
    setToDate("")
  }

  const handleFromDateChange = value => {
    const newFromDate = dayjs(value).format("YYYY-MM-DD")
    setFromDate(newFromDate)

    // Automatically set "toDate" to be the same or after "fromDate"
    if (dayjs(newFromDate).isAfter(toDate)) {
      setToDate(newFromDate)
    }
  }

  const handleToDateChange = value => {
    const newToDate = dayjs(value).format("YYYY-MM-DD")
    setToDate(newToDate)
  }

  return (
    <div className={classes.MyOrders}>
      <h3 className={classes.title}>My Orders</h3>
      <OngoingOrders />

      <div className={classes.table}>
        <CustomTable
          data={orderHistoryData}
          columns={orderHistory}
          loader={OrderHistoryLoading}
          onSort={handleSort}
          paginationTotalRows={total}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          children={
            <div className={classes.header_table}>
              <h3>Order History</h3>
              <div className={classes.header_table_box}>
                <div className={classes.header_table_left}>
                  <p className={classes.header_titel}>{showTotal} Items</p>

                  <FromToDatePicker
                    label={"From Date"}
                    format="DD-MM-YYYY"
                    value={dayjs(fromDate)}
                    onChange={handleFromDateChange}
                    error={false}
                    sx={{
                      borderRadius: "8px", // Set desired border radius
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px", // Ensure the inner input has the same border radius
                      },
                    }}
                  />

                  {/* To Date Picker */}
                  <FromToDatePicker
                    label={"To Date"}
                    format="DD-MM-YYYY"
                    value={dayjs(toDate)}
                    minDate={dayjs(fromDate)}
                    onChange={handleToDateChange}
                    disabled={!fromDate}
                    error={false}
                    sx={{
                      borderRadius: "8px", // Set desired border radius
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px", // Ensure the inner input has the same border radius
                      },
                    }}
                  />

                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#32349B",
                      fontSize: "16px",
                      fontFamily: "var(--font-bold)",
                      cursor: "pointer",
                    }}
                    onClick={clearSate}
                  >
                    Clear
                  </a>
                </div>
                <div className={classes.header_table_right}>
                  <SearchExpand
                    placeholder="Search products..."
                    onSearchChange={value => setSearchVlue(value)}
                    maxWidth="500px"
                  />
                  <div className={classes.filters}></div>
                  <DropDownFile handleFileDownload={pdfHandleSelect} />
                </div>
              </div>
            </div>
          }
        />
      </div>
      <ToastContainer />
    </div>
  )
}

export default MyOrdersMain
