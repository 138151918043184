import React, { useState } from "react"
import classes from "./OngoingOrders.module.css"
import RecentOrders from "./RecentOrders"
import CustomButton from "../../components/UI/Button/Button"
import ComplaintModal from "./Modal/ComplaintModal"

const OngoingOrders = () => {
  const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
  }
  const onConfirm = () => {
    setOpen(false) // Close the modal after confirming
  }

  return (
    <div className={classes.ongingOrder}>
      <>
        <div className={classes.top_alignment}>
          <div>
            <p className={classes.title}>Open Complaints</p>
            <p className={classes.subTitle}>{value.length} Complaints</p>
          </div>
          <CustomButton
            variant="contained"
            customColor="white"
            customBgColor="#32349b"
            custmstyle={{
              padding: "5px 7px",
              width: "180px",
              height: "45px", // Corrected height unit (was missing 'p')
              borderRadius: "8px",
            }}
            onClick={handleOpen} // Corrected the invocation
          >
            <span style={{ fontSize: "20px", marginRight: "5px" }}>+</span> New
            Complaint
          </CustomButton>
        </div>
        <div className={classes.recentOrder}>
          <RecentOrders setValue={setValue} />
        </div>
      </>
      {open && (
        <ComplaintModal
          open={open}
          onConfirm={onConfirm}
          handleClose={handleClose}
        />
      )}
    </div>
  )
}

export default OngoingOrders
