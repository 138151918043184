import React, { useState, useEffect } from "react"
import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { useNavigate, useSearchParams, useNavigation } from "react-router-dom"
import secureLocalStorage from "react-secure-storage"

import CustomButton from "../../../components/UI/Button/Button"
import PhoneNumInput from "../../../components/UI/PhoneNumInput/PhoneNumInput"
import useInput from "../../../utils/use-input"
import { validatePhoneNumber } from "../../../utils/validation"
import classes from "./LoginForm.module.css"
import useApiHttp from "../../../hooks/ues-http"
import { useDispatch } from "react-redux"
import { authActions } from "../../../services/storeSlice/authSlice"
import { isAuthenticated } from "../../../services/isAuthenticated"
import { toast, ToastContainer } from "react-toastify"

const LoginForm = () => {
  const navigate = useNavigate()

  const [checkvalue, setCheckValue] = useState(false)
  const dispatch = useDispatch()
  const { setMobileData } = authActions
  if (isAuthenticated()) {
    navigate("/")
  }

  const {
    value: enteredNum,
    rawPhone,
    dialCode,
    phoneIsValid: enteredNumIsValid,
    phoneHasError: enteredNumHasError,
    reactPhoneChangeHandler: phoneNumChangeHandler,
    inputBlurHandler: phoneNumBlurHandler,
    reset: resetPhoneNum,
  } = useInput(validatePhoneNumber)
  const {
    isLoading: sendOtpLoading,
    success: sendOtpSucces,
    error: sendOtpError,
    sendRequest: sendOtp,
  } = useApiHttp()

  useEffect(() => {
    const rememberedUser = secureLocalStorage.getItem("rememberedUser")

    // if (rememberedUser) {
    //   (true)
    // }
  }, [])

  const handleLogin = e => {
    e.preventDefault()

    if (enteredNumIsValid) {
      sendOtp(
        {
          url: `dealer/send-otp`,
          method: "POST",
          body: {
            country_code: String(dialCode),
            mobile_number: rawPhone,
          },
        },
        data => {
          // if (otpSent) {
          if (checkvalue) {
            // Save user login information in localStorage
            secureLocalStorage.setItem("rememberedUser", enteredNum)
          } else {
            // Clear remembered login info
            secureLocalStorage.removeItem("rememberedUser")
          }
          dispatch(
            setMobileData({ dialCode: String(dialCode), mobile: rawPhone })
          )
          // (true)
          navigate("/otp")
          //     }
        }
      )

      // (true)
    } else {
      // Handle invalid phone number error
    }
  }

  useEffect(() => {
    if (sendOtpSucces !== "") {
      toast.success(sendOtpSucces, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (sendOtpError !== "") {
      toast.error(sendOtpError, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [sendOtpSucces, sendOtpError])

  const handleChange = event => {
    setCheckValue(event.target.checked)
  }

  return (
    <div className={classes.loginFrom}>
      <div className={classes.loginHeader}>
        <h3>Log In</h3>
        <p>Enter your mobile number to continue</p>
      </div>
      <form onSubmit={handleLogin}>
        <PhoneNumInput
          specialLabel="Enter Your Mobile Number"
          inputProps={{
            name: "mobile_number",
            required: true,
          }}
          country="in"
          autoFormat={false}
          value={enteredNum}
          onChange={(value, data, e) => {
            phoneNumChangeHandler(value, data, e)
          }}
          phoneHasError={enteredNumHasError}
          onBlur={phoneNumBlurHandler}
        />

        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkvalue}
                onChange={handleChange}
                name="rememberMe"
                sx={{
                  color: "var(--light-gray)", // Default color
                  "&.Mui-checked": {
                    color: "var(--primary-blue)", // Color when checked
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: 23, // Custom checkbox size
                  },
                }}
              />
            }
            label="Remember Me"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "var(--text-color-dark)", // Custom label color
                fontSize: "13px", // Custom label font size
                fontWeight: "400",
              },
            }}
          />
        </Stack>
        <CustomButton
          variant="contained"
          customColor="white"
          customBgColor="#32349b"
          custmstyle={{ padding: "7px 7px", width: "100%", marginTop: "15px" }}
          type="submit"
        >
          Login
        </CustomButton>
      </form>

      {/* { && <p>Welcome back!</p>} */}
      <ToastContainer />
    </div>
  )
}

export default LoginForm
