import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import classes from "./Suggestions.module.css"
import TickIcon from "../../../components/UI/svgComponents/TickIcon"
import remove from "../../../assets/image/svg/remove.svg"
import addcart from "../../../assets/image/svg/add to cart.svg"
import { Paper, List, ListItem, CircularProgress } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import Selecter from "../../../components/UI/Dropdown/Select"
import {
  fetchList,
  fetchRemoveList,
  setSubCategory,
} from "../../../services/storeSlice/shopNowSlice"
import useApiHttp from "../../../hooks/ues-http"
import CustomeSlecter from "../../../components/UI/Dropdown/CustomeSlecter"

import debounce from "lodash/debounce"

const SuggestionsAllOrders = () => {
  const dispatch = useDispatch()
  const summaryData = useSelector(state => state.shopNow)
  const materialAndFinish = summaryData.materialAndFinish
  let cartData = summaryData?.cartData?.cart_items

  console.log("summaryData", summaryData.products)

  const [metaData, setMetaData] = useState()

  const [materials, setMaterials] = useState([])
  const [coatings, setMCoatings] = useState([])
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])

  const [materialsToken, setMaterialsToken] = useState("")
  const [coatingsToken, setMCoatingsToken] = useState("")
  const [categoriesToken, setCategoriesToken] = useState("")
  const [subcategoriesToken, setSubcategoriesToken] = useState("")
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false) // Add loading state
  const [page, setPage] = useState(1) // Track current page
  const [total, setTotal] = useState(0) // Track current page
  const containerRef = useRef(null)

  const {
    isLoading: addCardDataLoading,
    success: addCardDataSucces,
    error: addCardDataError,
    sendRequest: addCardDataRequest,
  } = useApiHttp()

  const {
    isLoading: alldealerLoading,
    success: alldealerSucces,
    error: alldealerError,
    sendRequest: alldealerRequest,
  } = useApiHttp()

  const {
    isLoading: ListDataLoading,
    success: ListDataSucces,
    error: ListDataError,
    sendRequest: ListDataRequest,
  } = useApiHttp()

  var searchValue =
    summaryData.searchTerm ||
    summaryData.searchbyitemcode ||
    summaryData.searchByAllData ||
    ""

  const addCardHandleSelect = token => {
    addCardDataRequest(
      {
        url: `dealer/shop-now/cart/add`,
        method: "POST",
        body: {
          product_token: token,
          search_value: searchValue,
        },
      },
      data => {
        dispatch(fetchList())
      }
    )
  }

  const {
    isLoading: metaDataLoading,
    success: metaDataSucces,
    error: metaDataError,
    sendRequest: metaDataRequest,
  } = useApiHttp()

  useEffect(() => {
    metaDataRequest(
      {
        url: `dealer/shop-now/drop-down`,
      },
      data => {
        setMetaData(data?.data || {}) // Ensure data is safely handled
      }
    )
  }, [])

  useEffect(() => {
    if (metaData?.materials) {
      setMaterials(metaData.materials)
    }
    if (metaData?.coatings) {
      setMCoatings(metaData.coatings)
    }
    if (metaData?.categories) {
      setCategories(metaData.categories)
    }
  }, [metaData])

  // Handle category change
  const handleCategoryChange = categoryToken => {
    setCategoriesToken(categoryToken)
    setPage(1)
    setData([])
    setSubcategoriesToken("")
    const category = categories?.find(cat => cat.token === categoryToken)
    if (category) {
      setSubcategories(category?.subcategory || [])
    } else {
      setSubcategories([])
      dispatch(setSubCategory(""))
    }
    // setSelectedSubCategory("")
  }

  // Handle subcategory change
  const handleSubCategoryChange = subCategoryToken => {
    setSubcategoriesToken(subCategoryToken)
    setPage(1)
    setData([])
  }

  // Handle subcategory change
  const handleMaterialChange = material => {
    setMaterialsToken(material)
    setPage(1)
    setData([])
  }
  // Handle subcategory change
  const handleSubFinishChange = finish => {
    setMCoatingsToken(finish)
    setPage(1)
    setData([])
  }

  const removeCartIteme = token => {
    dispatch(fetchRemoveList({ body: { cart_item_token: token } }))
      .unwrap()
      .then(() => {
        dispatch(fetchList())
      })
  }

  // Add debounce for filter changes to avoid unnecessary API calls
  const debouncedFetchData = useCallback(
    debounce(() => {
      ListDataRequest(
        {
          url: `dealer/shop-now/all-dealer/ordered-products`,
          method: "POST",
          body: {
            per_page: 10,
            page: page,
            category_token: categoriesToken,
            sub_category_token: subcategoriesToken,
            coating_token: coatingsToken,
            material_token: materialsToken,
          },
        },
        response => {
          setData(prevData => [...prevData, ...response?.data?.products])
          setTotal(response?.data?.total)
          setIsFetching(false)
        }
      )
    }, 500),
    [categoriesToken, subcategoriesToken, coatingsToken, materialsToken, page]
  )

  useEffect(() => {
    setIsFetching(true)
    debouncedFetchData()
  }, [categoriesToken, subcategoriesToken, coatingsToken, materialsToken])

  // Scroll event listener to detect when user reaches end of list
  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      if (scrollHeight - scrollTop <= clientHeight) {
        setPage(prevPage => prevPage + 1)
        debouncedFetchData()
      }
    }

    const refCurrent = containerRef.current
    if (refCurrent) {
      refCurrent.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener("scroll", handleScroll)
      }
    }
  }, [debouncedFetchData])

  // Memoized list rendering
  const renderedSuggestions = useMemo(() => {
    if (!data?.length) {
      return (
        <div
          style={{
            textAlign: "center",
            background: "#fff",
            marginTop: ".5rem",
            padding: " 3px",
            borderRadius: "5px",
          }}
        >
          No Data Available
        </div>
      )
    }

    return data?.map((item, index) => (
      <ListItem
        key={index}
        button
        sx={{ borderBottom: "1px solid var(--light-outline)" }}
      >
        <div className={classes.box}>
          <div className={classes.box_left}>
            <div className={classes.side_box}>
              <img
                src={item?.url}
                alt="product"
                style={{ width: "50px", height: "50px" }}
              />
              <div className={classes.side_box_contant}>
                <h3>{item?.product_code}</h3>
                <p>{`${item?.name} | ${item?.category} | ${item?.sub_category} | ${item?.coating} | ${item.material}`}</p>
              </div>
            </div>
          </div>
          <div className={classes.box_right}>
            <label
              className={`label_widgets ${
                item.stock_status === 1 ? "in_stock" : "out_stock"
              }`}
            >
              {item.stock_status === 1 ? "In Stock" : "Out of Stock"}
            </label>
            <label className="rate_box">
              <span className="count">₹ {item.item_rate}</span>
              <span className="line_through_price">₹ {item.mrp}</span>
            </label>

            {cartData.find(x => x.product_token === item.token) ? (
              <>
                <span className="added_cart">
                  <TickIcon />
                  Added to Cart
                </span>
                <span
                  className="cursor iconaddcart"
                  aria-label="Remove from cart"
                  onClick={() =>
                    removeCartIteme(
                      cartData.find(x => x.product_token === item.token)
                        ?.cart_item_token
                    )
                  }
                >
                  <img src={remove} alt="Remove from cart" />
                </span>
              </>
            ) : (
              <span
                className="cursor iconaddcart"
                aria-label="Add to cart"
                onClick={() => addCardHandleSelect(item.token)}
              >
                <img src={addcart} alt="Add to cart" />
              </span>
            )}
          </div>
        </div>
      </ListItem>
    ))
  }, [data, cartData, summaryData, materialAndFinish]) // Only recompute when `data` changes

  return (
    <div className={classes.suggestion_box}>
      <div className={classes.suggestion_result}>
        <div className={classes.suggestion_result_right}>
          <p>{total} Results</p>
          <div>
            <CustomeSlecter
              data={
                categories?.length > 0
                  ? categories.map(category => ({
                      label: category.name,
                      value: category.token,
                    }))
                  : [] // Provide an empty array as fallback
              }
              title={"Category"}
              width={150}
              value={categoriesToken}
              onChange={e => handleCategoryChange(e.target.value)}
            />
            <CustomeSlecter
              data={
                subcategories?.length > 0
                  ? subcategories.map(sub => ({
                      label: sub.name,
                      value: sub.token,
                    }))
                  : [] // Provide an empty array as fallback
              }
              title={"Sub-Category"}
              width={180}
              value={subcategoriesToken}
              onChange={e => handleSubCategoryChange(e.target.value)}
            />
          </div>
        </div>
        <div>
          <CustomeSlecter
            data={
              materials?.length > 0
                ? materials.map(sub => ({
                    label: sub.name,
                    value: sub.token,
                  }))
                : [] // Provide an empty array as fallback
            }
            title={"Material"}
            width={130}
            value={materialsToken}
            onChange={e => handleMaterialChange(e.target.value)}
          />
          <CustomeSlecter
            data={
              coatings?.length > 0
                ? coatings.map(sub => ({
                    label: sub.name,
                    value: sub.token,
                  }))
                : [] // Provide an empty array as fallback
            }
            title={"Finish"}
            width={130}
            value={coatingsToken}
            onChange={e => handleSubFinishChange(e.target.value)}
          />
        </div>
        {/* <img
          src={remove}
          alt="Remove from cart"
          className={classes.suggestion_remove}
          ref={containerRef}
        /> */}
      </div>
      <Paper
        elevation={3}
        ref={containerRef}
        style={{
          maxWidth: "100%",
          marginTop: "5px",
          position: "relative",
          zIndex: 50,
          marginBottom: "20px",
          maxHeight: "384px",
          overflow: "auto",
        }}
      >
        <List>
          {" "}
          {isFetching ? (
            <div className={classes.loading_spinner}>
              <CircularProgress />
            </div>
          ) : (
            renderedSuggestions
          )}
        </List>
      </Paper>
    </div>
  )
}

export default SuggestionsAllOrders
