import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import marketingStore from "../modules/marketing/store/reduxStore";
import Login from "../modules/marketing/pages/Login/Login";
import ForgotPassword from "../modules/marketing/pages/Login/ForgotPassword";
import Product from "../modules/marketing/pages/Product/Product";
import MainLayout from "../modules/marketing/layout/MainLayout";
import WithAuthentication from "../modules/marketing/HOC/WithAuthentication";
import { OrderManagement } from "../modules/marketing/pages/OrderManagement/OrderManagement";
import { OrderDetails } from "../modules/marketing/pages/OrderDetails/OrderDetails";
import MyProfile from "../modules/marketing/pages/myProfile/MyProfile";
import DealerList from "../modules/marketing/pages/DealerList/DealerList";
import ComplaintManagement from "../modules/marketing/pages/ComplaintManagement/ComplaintManagement";
import ComplaintDetails from "../modules/marketing/pages/ComplaintManagement/ComplaintDetails/ComplaintDetails";


export const marketRoutes = createBrowserRouter([
  {
    path: "/operations",
    children: [

      {
        path: "login",
        element: <WithAuthentication checkType={false} AuthCheckElement={<Login />} />,
      },
      {
        path: "forgot-password",
        element: <WithAuthentication checkType={false} AuthCheckElement={<ForgotPassword />} />
        // element: <ForgotPassword />
      },
      {
        // Redirect `/marketing` to `/marketing/product`
        index: true,
        element: <Navigate to="/operations/product" replace />,
      },
      {
        // Main layout and child routes
        element: <MainLayout />,
        children: [
          {
            // index: true,
            path: "product",
            element: <WithAuthentication AuthCheckElement={<Product />} />,

          }, {
            path: "dealer_list",
            element: <WithAuthentication AuthCheckElement={<DealerList />} />,

          }, {
            index: true,
            path: "order_management",
            element: <WithAuthentication AuthCheckElement={<OrderManagement />} />,
          }, {
            index: true,
            path: "/operations/order/:id",
            element: <WithAuthentication AuthCheckElement={<OrderDetails />} />,
          }, {
            path: "complaints",
            element: <WithAuthentication AuthCheckElement={<ComplaintManagement />} />,
          }, {
            path: "complaints/:id",
            element: <WithAuthentication AuthCheckElement={<ComplaintDetails />} />,
          },
          {
            path: "my_profile",
            element: <WithAuthentication AuthCheckElement={<MyProfile />} />,
          },
        ],
      },

    ],
  },
]);

export default function Marketing() {
  return <Provider store={marketingStore}>

    <RouterProvider router={marketRoutes} />
  </Provider>
}



