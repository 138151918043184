import React, { useState, useEffect, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import classes from "./getAddress.module.css"
import ModalChekBoxWithLable from "../../../components/UI/Checkbox/modalCheckBox/ModalChekBoxWithLable"
import { Stack, Typography } from "@mui/material"
import ButtonComponent from "../../../components/UI/Button/Button"
import AddressCheckBoxComp from "./AddressCheckBox/AddressCheckBoxComp"
import SingleOrderEstimate from "./singleOrderEstimate/SingleOrderEstimate"
import useApiHttp from "../../../hooks/ues-http"
import { useDispatch, useSelector } from "react-redux"
import { setSteperVlue } from "../../../services/storeSlice/shopNowSlice"
import { updateSummary } from "../../../services/summarySlice/summaryDataSlice"
import timer from "../../../assets/image/svg/timer.svg"
import CountdownTimer from "../../../components/UI/CountdownTimer/CountdownTimer"
import { toast, ToastContainer } from "react-toastify"
import { resetTimer } from "../../../services/storeSlice/timerSlice"

const GetAddress = () => {
  const [billingAddress, setbillingAddress] = useState([])
  const [shippingAddress, setshippingAddress] = useState([])
  const [deliveryEstimate, setdeliveryEstimate] = useState([])
  const [selectedBillingAddress, setSelectedBillingAddress] = useState({})
  const [selectedShippingAddress, setSelectedShippingAddress] = useState({})
  const [orderSummary, setorderSummary] = useState({})
  const [checkBill, setCheckBill] = useState(true)
  const [disableShippingAddress, setdisableShippingAddress] = useState(false)
  const { sendRequest: fetchAddresses } = useApiHttp()
  const {
    isLoading: placeOrderLoading,
    success: placeOrderSucces,
    error: placeOrderError,
    sendRequest: placeOrder,
  } = useApiHttp()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { timeLeft, isActive } = useSelector(state => state.timer)

  useEffect(() => {
    if (placeOrderSucces !== "") {
      toast.success(placeOrderSucces, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (placeOrderError !== "") {
      toast.error(placeOrderError, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [placeOrderSucces, placeOrderError])

  useEffect(() => {
    fetchAddresses(
      {
        url: `dealer/dealer-address`,
      },
      data => {
        let updatedBillingAddress = data?.data?.billing_address.map(item => ({
          ...item,
          checkVal: false,
        }))
        let updatedShippingAddress = data?.data?.shipping_address.map(item => ({
          ...item,
          checkVal: false,
        }))
        if (data?.data?.delivery_estimates.length <= 0) {
          //   navigate("/myorders")
          localStorage.removeItem("active")
        }

        setbillingAddress(updatedBillingAddress)
        setshippingAddress(updatedShippingAddress)
        setdeliveryEstimate(data?.data?.delivery_estimates)
        setorderSummary(data?.data?.order_summary)
      }
    )
  }, [])

  const billingAddressChange = (token, checkedStatus) => {
    if (!checkedStatus) {
      let updatedArray = billingAddress.map((item, index) => {
        if (item.token === token) {
          return { ...item, checkVal: checkedStatus }
        }
        return item
      })
      let checkDisableLength = updatedArray.filter(
        item => item.checkVal === true
      )
      setSelectedBillingAddress({})
      setbillingAddress(updatedArray)
      setCheckBill(checkDisableLength.length <= 0 ? true : checkBill)
    } else {
      let updatedArray = billingAddress.map((item, index) => {
        if (item.token === token) {
          const { checkVal, ...restObject } = item
          setSelectedBillingAddress(restObject)
        }

        // if (item.checkVal === true) {
        return {
          ...item,
          checkVal: item.token !== token ? false : checkedStatus,
        }
        // } else {
        //     return item;

        // }
      })

      setbillingAddress(updatedArray)
      setCheckBill(!checkedStatus)
    }
  }

  const shippingAddressChange = (token, checkedStatus) => {
    if (!checkedStatus) {
      let updatedArray = shippingAddress.map((item, index) => {
        if (item.token === token) {
          return { ...item, checkVal: false }
        }
        return item
      })
      setSelectedShippingAddress({})
      setshippingAddress(updatedArray)
    } else {
      let updatedArray = shippingAddress.map((item, index) => {
        if (item.checkVal) {
          return { ...item, checkVal: false }
        } else if (item.token === token) {
          if (item.token === token) {
            const { checkVal, ...restObject } = item
            setSelectedShippingAddress(restObject)
          }
          return { ...item, checkVal: true }
        }
        return item
      })

      setshippingAddress(updatedArray)
    }
  }

  const disableShipAddress = (tok, checkedStatus) => {
    setdisableShippingAddress(checkedStatus)
    if (checkedStatus) {
      setSelectedShippingAddress(selectedBillingAddress)
    } else {
      setSelectedShippingAddress(shippingAddress.find(x => x.checkVal === true))
    }
  }

  const orderPlacement = () => {
    let apiBody = {
      billing_address: selectedBillingAddress,
      shipping_address: selectedShippingAddress,
      order_amount: orderSummary.total_item_amount,
      total_tax_amount: orderSummary.total_tax_amount,
      total_amount: orderSummary.total_amount,
      order_products: deliveryEstimate.map(item => ({
        product_token: item.product_token,
        quantity: item.product_quantity,
        requested_date: item.requested_date,
      })),
    }

    placeOrder(
      {
        url: `dealer/place-order`,
        method: "POST",
        body: apiBody,
      },
      data => {
        dispatch(updateSummary(data?.data))
        dispatch(setSteperVlue(2))
        if (data?.data?.status_code === 422) {
          toast.error(data?.data?.message || "Something went wrong!", {
            // position: "top-center"
          })
        }
        if (data?.data?.status_code === 400) {
          toast.error(data?.data?.message || "Something went wrong!", {
            // position: "top-center"
          })
        }
      }
    )
  }
  return (
    <div>
      <h3 className={classes.address_header}>Address</h3>
      {/* <h5 className={classes.address_sub_header}>Billing Address</h5> */}
      <div className={classes.address_order_detail_cont}>
        <div className={classes.address_details}>
          <h5 className={classes.address_sub_header}>Billing Address</h5>

          <div className="bill_address_div">
            {billingAddress &&
              billingAddress.map((item, index) => (
                <AddressCheckBoxComp
                  onChange={billingAddressChange}
                  manualCheckBox={true}
                  key={item?.token}
                  checkVal={item?.checkVal}
                  checkBoxValue={item?.token}
                  name={item?.name}
                  address1={item?.address_line_1}
                  address2={item?.address_line_2}
                  address3={`${item?.city}, ${item?.state}, ${item?.pincode}, ${item?.country}`}
                  mobile={item?.mobile_number}
                />
              ))}
          </div>
          <h5
            className={classes.address_sub_header}
            style={{ marginTop: "2rem" }}
          >
            Shipping Address
          </h5>
          <ModalChekBoxWithLable
            disabled={checkBill}
            onChange={disableShipAddress}
            containerStyles={{ alignItems: "flex-start" }}
            lableProps={{
              sub_header: "Shipping Information same as Billing Information",
              lable_div_styles: {
                marginTop: "10px",
              },
              sub_header_styles: {
                color: "var(--text-color-dark)",
                fontSize: "14px",
              },
            }}
          />
          <div
            className={`${classes.shipping_address_div} ${
              disableShippingAddress ? classes.hide : ""
            }`}
          >
            {shippingAddress &&
              shippingAddress.map((item, index) => (
                <AddressCheckBoxComp
                  onChange={shippingAddressChange}
                  manualCheckBox={true}
                  key={item?.token}
                  checkVal={item?.checkVal}
                  checkBoxValue={item?.token}
                  name={item?.name}
                  address1={item?.address_line_1}
                  address2={item?.address_line_2}
                  address3={`${item?.city}, ${item?.state}, ${item?.pincode}, ${item?.country}`}
                  mobile={item?.mobile_number}
                />
              ))}
          </div>
        </div>
        <div className={classes.order_details}>
          <CountdownTimer />
          <div className={classes.order_estimates}>
            <h5 className={classes.address_sub_header}>Delivery Estimates</h5>
            <div className={classes.single_order_estimate_div}>
              {deliveryEstimate &&
                deliveryEstimate.map((item, index) => (
                  <SingleOrderEstimate
                    key={index}
                    reqDate={item?.requested_date_format}
                    partNo={item.product_code}
                    name={item.name}
                  />
                ))}
              {/* <SingleOrderEstimate /> */}
            </div>
          </div>
          <div className={classes.order_summary}>
            <h5 className={classes.address_sub_header}>Order Summary</h5>
            <div className={classes.summary_details}>
              <div className={classes.summary_single_detail_div}>
                <Typography
                  sx={{
                    fontFamily: "var(--font-regular)",
                    color: "var(--text-color-dark)",
                    fontSize: "14px",
                  }}
                >
                  Order Value
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "var(--font-regular)",
                    color: "var(--text-color-dark)",
                    fontSize: "14px",
                  }}
                >
                  ₹{" "}
                  {orderSummary?.total_item_amount
                    ? (orderSummary?.total_item_amount).toLocaleString("en-IN")
                    : 0}
                </Typography>
              </div>
              <div className={classes.summary_single_detail_div}>
                <Typography
                  sx={{
                    fontFamily: "var(--font-regular)",
                    color: "var(--text-color-dark)",
                    fontSize: "14px",
                  }}
                >
                  Total Tax ({orderSummary.total_tax_percentage || 0}%)
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "var(--font-regular)",
                    color: "var(--text-color-dark)",
                    fontSize: "14px",
                  }}
                >
                  ₹{" "}
                  {orderSummary?.total_tax_amount
                    ? (orderSummary?.total_tax_amount).toLocaleString("en-IN")
                    : 0}
                </Typography>
              </div>
              <div className={classes.single_order_under_line}></div>
            </div>
            <div
              className={`${classes.summary_single_detail_div} ${classes.total}`}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font-bold)",
                  color: "var(--text-color-dark)",
                  fontSize: "16px",
                }}
              >
                Total Amount
              </Typography>
              <Typography
                sx={{
                  fontFamily: "var(--font-bold)",
                  color: "var(--status-green)",
                  fontSize: "16px",
                }}
              >
                ₹{" "}
                {orderSummary?.total_amount
                  ? (orderSummary?.total_amount).toLocaleString("en-IN")
                  : 0}
              </Typography>
            </div>
            <ButtonComponent
              customBgColor="var(--primary-blue)"
              custmstyle={{
                padding: "10px 7px",
                width: "100%",
                marginTop: "20px",
                borderRadius: "8px",
              }}
              onClick={orderPlacement}
              disabled={
                !selectedBillingAddress?.token ||
                !selectedShippingAddress?.token ||
                !selectedBillingAddress ||
                !selectedShippingAddress
                  ? true
                  : false
              }
            >
              Place Order
            </ButtonComponent>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default GetAddress
