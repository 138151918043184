import useApiHttp from "../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


const ProductServices = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    const FetchCategories = () =>
        useApiHttp({
            url: `marketing/category-list`,
            // method: "POST",
        });

    const FetchSubCategories = () =>
        useApiHttp({
            url: `marketing/subcategory-list`,
            method: "POST",
        });

    const FetchProducts = () =>
        useApiHttp({
            url: `marketing/product-list`,
            method: "POST",
            // onSuccess: (data) => {
            //     toast.success((data.message || 'Success!'), {
            //         position: "top-center"
            //     });
            //     dispatch(login(data.data));
            //     navigate("/marketing/product");
            // }
        });
    const ViewSingleProduct = () =>
        useApiHttp({
            url: `marketing/product-view`,
            method: "POST",

        });

    const DownloadTable = () =>
        useApiHttp({
            url: `marketing/product-download`,
            method: "POST",

        });



    return {
        FetchCategories,
        FetchSubCategories,
        FetchProducts,
        ViewSingleProduct,
        DownloadTable
    }

}

export default ProductServices