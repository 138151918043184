import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage"


import CustomButton from "../../../components/UI/Button/Button";
import PhoneNumInput from "../../../components/UI/PhoneNumInput/PhoneNumInput";
import useInput from "../../../utils/use-input";
import { validateEmail, validatePhoneNumber } from "../../../utils/validation";
import classes from "./LoginForm.module.css";
import styled from "styled-components";
import CustomTextField from "../../../components/UI/TextField/TextField";
import { Height } from "@mui/icons-material";
import useApiHttp from "../../../hooks/use-http";
import LoginServices from "../../../pages/Login/login.service";

const LoginForm = () => {
  const navigate = useNavigate();

  const [checkvalue, setCheckValue] = useState(false);
  const { LoginService } = LoginServices();
  const { sendRequest: loginHandle } = LoginService();
  // const { success: loginSuccess, sendRequest: loginHandle } = useApiHttp();

  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput(validateEmail);

  const {
    value: password,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPasswordInput,
  } = useInput((e) => {
    return e !== "";
  });

  useEffect(() => {
    let savedUserDetails = secureLocalStorage.getItem("marketingUserData");
    let savedUser = savedUserDetails?.remembered_user;
    if (savedUser?.login_name && savedUser?.login_name !== '') {
      let PasswordData = {
        target: {
          value: savedUser.password
        }
      }
      let emailData = {
        target: {
          value: savedUser.login_name
        }
      }
      emailChangeHandler(emailData)
      passwordChangeHandler(PasswordData)
      setCheckValue(true);
    }

  }, [])


  const handleLogin = (e) => {
    e.preventDefault();
    if (emailIsValid && passwordIsValid) {

      loginHandle({
        body: {
          "email": email,
          "password": password
        }
      }, () => {
        let userDataGet = secureLocalStorage.getItem("marketingUserData");
        let data = {};
        if (checkvalue) {

          if (userDataGet) {
            data = {
              ...userDataGet, remembered_user: {
                login_name: email,
                password: password
              }
            }
          } else {
            data = {
              remembered_user: {
                login_name: email,
                password: password
              },

            }
          }

          secureLocalStorage.setItem("marketingUserData", data);

        } else {
          if (userDataGet) {
            data = { ...userDataGet, remembered_user: {} }
          } else {
            data = {
              remembered_user: {},

            }
          }
          secureLocalStorage.setItem("marketingUserData", data);
        }
      });
    }

    // navigate("/marketing/product");
  };

  const handleChange = (event) => {
    setCheckValue(event.target.checked);
  };

  return (
    <div className={classes.loginFrom}>
      <div className={classes.loginHeader}>
        <h3>Welcome !</h3>
        <p>Sign in to your account to continue</p>
      </div>
      <form onSubmit={handleLogin}>
        <CustomTextField
          id="email"
          label="Email Address"
          variant="outlined"
          value={email}
          onChange={emailChangeHandler}
          error={emailHasError}
          onBlur={emailBlurHandler}
          helperText={emailHasError ? "Enter the email" : null}
        />
        <CustomTextField
          id="password"
          label="Password"
          variant="outlined"
          type={"password"}
          value={password}
          onChange={passwordChangeHandler}
          error={passwordHasError}
          onBlur={passwordBlurHandler}
          helperText={passwordHasError ? "Enter the password" : null}
        />
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checkvalue}
                onChange={handleChange}
                name="rememberMe"
                sx={{
                  color: "var(--light-gray)", // Default color
                  "&.Mui-checked": {
                    color: "var(--primary-blue)", // Color when checked
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: 23, // Custom checkbox size
                  },
                }}
              />
            }
            label="Remember Me"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "var(--text-color-dark)", // Custom label color
                fontSize: "13px", // Custom label font size
                fontWeight: "400",
              },
            }}
          />
          <Typography
            variant="p"
            sx={{
              fontSize: "13px",
              color: "#376FD0",
              fontWeight: "400",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/marketing/forgot-password");
            }}
          >
            Forgot Password?
          </Typography>
        </Stack>
        <CustomButton
          variant="contained"
          customColor="white"
          customBgColor="#376FD0"
          custmstyle={{
            padding: "16px 7px",
            width: "100%",
            marginTop: "15px",
          }}
          type="submit"
          disabled={!emailIsValid || !passwordIsValid}
        >
          Sign in
        </CustomButton>
      </form>

      {/* {isLoggedIn && <p>Welcome back!</p>} */}
    </div>
  );
};

export default LoginForm;
