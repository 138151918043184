import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import classes from './OrderTable.module.css'
import { Button, FormControl, Input, InputLabel, Menu, MenuItem, Select, SvgIcon, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import downloadIcon from '../../../assets/image/svg/download.svg'
import searchIcon from '../../../assets/image/svg/order_search.svg'
import { useNavigate } from 'react-router-dom';
import useApiCall from "../../../hooks/useApiCall";
import secureLocalStorage from 'react-secure-storage';
import { useState } from 'react';
import dayjs from 'dayjs';
import SnackbarComponent from '../../../components/UI/Snackbar/Snackbar';
import ViewModal from '../../../components/UI/viewModal/ViewModal';
import UploadIcon from '../../../components/UI/svgComponents/UploadIcon';
import tick from "../../../assets/image/svg/tick.svg"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: 'sno',
    numeric: false,
    disablePadding: true,
    label: 'S.No',
  },
  {
    id: 'order_id',
    numeric: true,
    disablePadding: false,
    label: 'Order ID',
  },
  {
    id: 'dealer_name',
    numeric: true,
    disablePadding: false,
    label: 'Dealer Name',
  },
  {
    id: 'ordered_date',
    numeric: true,
    disablePadding: false,
    label: 'Ordered Date',
  },
  {
    id: 'total_product',
    numeric: true,
    disablePadding: false,
    label: 'Total Product',
  },
  {
    id: 'total_order_value',
    numeric: true,
    disablePadding: false,
    label: 'Total Order Value',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Stauts',
  },
  {
    id: 'change_status',
    numeric: true,
    disablePadding: false,
    label: 'Change Status',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            sx={{
                width: '30px',
                height: '30px',
                '&.Mui-checked': {
                  color: '#C4C4C4',
                },
                '&.MuiCheckbox-root': {
                  color: '#C4C4C4',
                },
              }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <div className='line' style={{ height: '14px', backgroundColor: '#C4C4C4', width: "1px", marginRight: '7.92px' }}></div>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  data,
  totalOrders,
  searchTerm,
  setSearchTerm,
  setCurrentPageNumber,
  rowsPerPage,
  setRowsPerPage,
  selectedDealer,
  selectedStatus,
  fromDate,
  toDate,
  statusData,
  handleGenerate,
  active_orders
}) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rows, setRows] = React.useState([])
  const [changeStatus, setChangeStatus] = React.useState("Select")
  const [orderStatus, setOrderStatus] = React.useState({});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [selectedFileObject, setselectedFileObject] = React.useState("");
  const [selectedFileError, setselectedFileError] = React.useState("");
  const [fileUploadModal, setFileUploadModal] = React.useState(false);
  const [acknowledgementStatusUpdateData, setAcknowledgementStatusUpdateData] = React.useState({});
  const [snackBarseverity, setsnackBarseverity] = React.useState('success');


  function createData(sno, order_id, token, status_code, dealer_name, ordered_date, total_product, total_order_value, status) {
    return {
      sno, order_id, token, status_code, dealer_name, ordered_date, total_product, total_order_value, status
    };
  }

  React.useEffect(() => {
    const rowsData = []
    if (data) {
      data?.map((item, index) => {
        rowsData.push(createData(item.si_no, item.order_id, item.token, item.status_code, item.dealerName, item.order_date, item.no_of_item, item.total_cost, item.status))
      })
    }
    setRows(rowsData)
  }, [data])
  const { isLoading: isPdfUploading, success: pdfUploadSuccess, error: pdfUploadError, code: pdfUploadStatusCode, errorData: pdfUploadErrorData, sendRequest: pdfUpload } = useApiCall();

  React.useEffect(() => {
    if (pdfUploadError) {
      setSnackbarMessage(pdfUploadError)
      setsnackBarseverity('error')

      setOpenSnackbar(true)
    };
  }, [pdfUploadError])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // Select all rows
      const newSelecteds = rows.map((row) => row.token); // Use unique identifier
      setSelected(newSelecteds);
      return;
    }
    // Deselect all rows
    setSelected([]);
  };

  const handleClick = (event, token) => {
    const selectedIndex = selected.indexOf(token);
    let newSelected = [];

    if (selectedIndex === -1) {
      // If not already selected, add to selected array
      newSelected = newSelected.concat(selected, token);
    } else {
      // If already selected, remove from selected array
      newSelected = selected.filter((item) => item !== token);
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPageNumber(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCurrentPageNumber(0)
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     [...rows]
  //       .sort(getComparator(order, orderBy))
  //       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
  //   [order, orderBy, page, rowsPerPage],
  // );

  const statusCard = (status) => {
    return (
      <div className={classes.status_card_container}>
        {
          status === "Pending" ?
            <p className={classes.pending}>{status}</p>
            :
            status === "Partially Dispatched" ?
              <p className={classes.partially_dispatched}>{status}</p>
              :
            status === "Dispatched" ?
              <p className={classes.dispatched}>{status}</p>
              :
              status === "Order Acknowledged" ?
                <p className={classes.order_acknowledged}>{status}</p>
                :
                status === "Delivered" &&
                <p className={classes.delivered}>{status}</p>
        }
      </div>
    )
  }

  const handleOrderDetails = (token) => {
    navigate(`/operations/order/${token}`, { state: { fromDate: fromDate, toDate: toDate, type: active_orders, status_data: statusData } });
  }

  const { isLoading: isDownloadLoading, success: downloadSuccess, error: downloadError, code: downloadStatusCode, errorData: downloadErrorData, sendRequest: download } = useApiCall();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadOption = (format) => {
    // Implement your download logic here based on the selected format
    console.log(`Download as ${format}`);
    handleDownload(format)
    handleClose(); // Close the menu after selection
  };

  const handleDownload = (format) => {
    download({
      url: "marketing/ongoing-closed-order-list-download",
      method: 'POST',
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem('marketingBearerToken')}`
      },
      body: {
        "order_type": "1",
        "file_type": format,
        "order_tokens": selected,
        "search": {
          "value": searchTerm
        },
        "to_date": toDate === null ? dayjs().format('YYYY-MM-DD') : toDate,
        "from_date": fromDate === null ? dayjs().format('YYYY-MM-DD') : fromDate,
        "dealer_token": selectedDealer === "Select" ? "" : selectedDealer,
        "status": selectedStatus === "Select" ? "" : selectedStatus,
      },
    }, (response) => {
      downloadFile(response.data, format)
    });
  }

  const downloadFile = async (pdfUrl, format) => {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    a.download = `order_management_ongoing_${timestamp}.${format}`; // Specify the name for the downloaded file
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url); // Clean up the URL object
  };

  // Handle status change for a specific order
  const handleChangeStatus = (order_id, item) => {
    if (item.target.value === "1") {
      setFileUploadModal(true)
      setAcknowledgementStatusUpdateData({ value: item.target.value, order_token: order_id })
    } else {
      setOrderStatus((prevStatus) => ({
        ...prevStatus,
        [order_id]: item.target.value, // Use order_id as key to store its status
      }));
      changeStatusApi(order_id, item.target.value)
    }
  };

  const { isLoading: isGenerateLoading, success: generateSuccess, error: generateError, code: generateStatusCode, errorData: generateErrorData, sendRequest: change } = useApiCall();

  const changeStatusApi = (order_id, status) => {
    change({
      url: "marketing/order-status-update",
      method: 'POST',
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem('marketingBearerToken')}`
      },
      body: {
        "order_token": order_id,
        "order_status": status
      }
    }, (response) => {
      console.log("response", response);
      if (acknowledgementStatusUpdateData) {
        setFileUploadModal(false)
        setAcknowledgementStatusUpdateData({});
      }
      setSnackbarMessage(response.message)
      setsnackBarseverity('success')
      setOpenSnackbar(true)
      handleGenerate()
    });
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleFileChange = e => {
    const file = e.target.files[0] // Get the first selected file
    console.log(e.target);
    console.log(e.target.value);
    // return

    if (file) {
      // Allowed types: jpeg, png, jpg, pdf
      const allowedTypes = [

        "application/pdf",
      ]

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        setselectedFileError(
          "Invalid file type! Please select a PDF."
        )
        setselectedFileObject(null)
        // setImagePreview("")
        return
      }

      // Check if the file size is less than or equal to 2MB
      const maxSize = 2 * 1024 * 1024 // 2MB in bytes
      if (file.size > maxSize) {
        setselectedFileError("File size exceeds 2MB! Please select a smaller file.")
        setselectedFileObject(null)
        // setImagePreview("")
        return
      }

      // If everything is valid, set the file and clear the error
      setselectedFileObject(file)
      setselectedFileError("")

      // If it's an image, create a preview
      // if (file.type.startsWith("image/")) {
      //   const reader = new FileReader()
      //   reader.onload = e => {
      //     setImagePreview(e.target.result) // Set the preview URL
      //   }
      //   reader.readAsDataURL(file)
      // } else {
      //   // If it's a PDF, clear any previous image preview
      //   setImagePreview("pdf")
      // }
    }
  }

  const RenderModalContent = () => (
    <Box mt={3}>
      <p style={{ color: "#1514145e", fontSize: "12px", fontFamily: "var(--font-regular)" }}>Upload Order Acknowledgement</p>


      <Box className={classes.upload_container}>
        {!selectedFileObject && (
          <label htmlFor='acknowledgemnet-pdf' style={{ display: "inline-block", textAlign: "center", fontSize: "12px", fontFamily: "var(--font-regular)" }}>
            <UploadIcon />
            <p>Upload Your PDF file</p>
            {/* <Input
        type="file"
        id="file-upload"
        accept=".jpg, .png, .jpeg, .pdf"
      // onChange={handleFileChange}
      /> */}
            {selectedFileError && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {selectedFileError}
              </Typography>
            )}
          </label>
        )}

        {selectedFileObject && (
          <div className={classes.pdfPreview}>
            <img
              src={tick} // Use a PDF icon if available
              alt="PDF Preview"
              style={{
                width: "60px",
                maxWidth: "200px",
                height: "auto",
              }}
            />
            <p>{selectedFileObject.name}</p>
            <p>File Loaded Successfully.</p>
            <label style={{ fontSize: "12px", fontFamily: "var(--font-regular)", cursor: "pointer", color: "#1976d2" }} htmlFor='acknowledgemnet-pdf'>Change</label>
          </div>
        )}

      </Box>
      {/* {selectedFileError && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {selectedFileError}
        </Typography>
      )} */}



      {/* {selectedFileObject && (
        <div className={classes.pdfPreview}>
          <img
            src={tick} // Use a PDF icon if available
            alt="PDF Preview"
            style={{
              width: "60px",
              maxWidth: "200px",
              height: "auto",
            }}
          />
          <p>{selectedFileObject.name} Loaded Successfully.</p>
          <label htmlFor='acknowledgemnet-pdf'>Change</label>
        </div>
      )} */}
      <input style={{ display: "none" }} type='file' onChange={handleFileChange} accept='.pdf' id='acknowledgemnet-pdf' />

    </Box>
  )

  const RenderCta = () => {
    const cancelCta = () => {
      setselectedFileObject('');
      setselectedFileError('');
      setFileUploadModal(false)

    }

    const onSubmitCta = () => {
      if (selectedFileObject) {
        // let body = JSON.stringify({
        //   "order_token": acknowledgementStatusUpdateData.order_token,
        //   "acknowledgement_document": selectedFileObject,
        // })

        let body = new FormData();
        body.append('order_token', acknowledgementStatusUpdateData.order_token);
        body.append('acknowledgement_document', selectedFileObject);
        pdfUpload({
          url: "marketing/acknowledgement-document-upload",
          method: 'POST',
          headers: {
            Authorization: `Bearer ${secureLocalStorage.getItem('marketingBearerToken')}`,
            "Content-Type": "multipart/form-data"
          },
          body: body
        }, (response) => {
          setSnackbarMessage(response.message)
          setsnackBarseverity('success')

          setOpenSnackbar(true)
          setselectedFileObject(null)
          setselectedFileError("")
          setOrderStatus((prevStatus) => ({
            ...prevStatus,
            [acknowledgementStatusUpdateData.order_token]: acknowledgementStatusUpdateData.value, // Use order_id as key to store its status
          }));
          changeStatusApi(acknowledgementStatusUpdateData.order_token, acknowledgementStatusUpdateData.value)


        })


      } else {
        setselectedFileError('Upload PDF')
      }

    }
    return (
      <>
        <Button sx={{ color: "grey", fontFamily: "var(--font-semibold)" }} onClick={cancelCta} className={classes.button_style}>Cancel</Button>
        <Button sx={{ fontFamily: "var(--font-semibold)" }} onClick={onSubmitCta} className={classes.button_style}>Submit</Button>

      </>

    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <div className={classes.table_header_container}>
          <p className={classes.table_header_title}>Ongoing Order List</p>
          <div className={classes.table_header_total_search}>
            <p className={classes.table_header_sub_title}>{totalOrders} Orders</p>
            <div className={classes.icons_container}>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                size="small"
                value={searchTerm}
                sx={{ marginRight: 1 }}
                onChange={(event) => {
                  localStorage.setItem('searchTerm', event.target.value);
                  setCurrentPageNumber(0)
                  setPage(0);
                  setSearchTerm(event.target.value)
                }}
              />
              <img src={searchIcon} width={24} height={24} />
              <div className={classes.divider}></div>
              <img onClick={handleDownloadClick} src={downloadIcon} width={24} height={24} />
            </div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleDownloadOption('csv')}>CSV</MenuItem>
              <MenuItem onClick={() => handleDownloadOption('pdf')}>PDF</MenuItem>
              <MenuItem onClick={() => handleDownloadOption('xls')}>Excel</MenuItem>
            </Menu>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleDownloadOption('csv')}>CSV</MenuItem>
            <MenuItem onClick={() => handleDownloadOption('pdf')}>PDF</MenuItem>
            <MenuItem onClick={() => handleDownloadOption('xls')}>Excel</MenuItem>
          </Menu>
        </div>
        <TableContainer>
          {
            rows.length > 0 ?
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = selected.includes(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={selected.includes(row.token)}
                        tabIndex={-1}
                        key={row.id}
                        selected={selected.includes(row.token)}
                        sx={{ cursor: 'pointer' }}
                      >
                        {/* <TableCell
                          align="left"
                          padding="checkbox"
                          sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}
                        >
                          <Checkbox
                            color="primary"
                            onClick={(event) => handleClick(event, row.token)} // Use token as identifier
                            checked={selected.includes(row.token)} // Check if token is in selected array
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            sx={{
                              width: '30px',
                              height: '30px',
                              '&.Mui-checked': {
                                color: '#C4C4C4',
                              },
                              '&.MuiCheckbox-root': {
                                color: '#C4C4C4',
                              },
                            }}
                          />
                        </TableCell> */}
                        <TableCell
                          align="left"
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                          sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}
                        >
                          {row.sno}
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>
                          <span onClick={() => handleOrderDetails(row.token)} className={classes.order_id_text}>{row.order_id}</span>
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.dealer_name}</TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.ordered_date}</TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.total_product}</TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>{row.total_order_value}</TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>
                          {statusCard(row.status)}
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: '1px dashed', borderColor: '#D8DBDD' }}>
                          <div className={classes.form_element}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Choose Status</InputLabel>
                              <Select
                                size="small"
                                labelId={`select-label-${row.token}`}
                                id={`select-${row.token}`}
                                value={row.status_code}
                                disabled={row.status_code > 0}
                                label="Choose Status"
                                onChange={(item) => handleChangeStatus(row.token, item)}
                                sx={{
                                  borderRadius: '8px', // Set the desired border radius
                                  '& .MuiSelect-select': {
                                    borderRadius: '8px', // Ensure the inner select has the same border radius
                                    fontFamily: 'Proxima-Regular', // Change to your desired font family for the value
                                  },
                                  '& .MuiSelect-icon': {
                                    fontFamily: 'Proxima-Regular', // Change font family for the dropdown icon if needed
                                  },
                                }}
                              >
                                <MenuItem value={"Select"}>Select</MenuItem>
                                {statusData?.map((item, index) => {
                                  // Determine if the item should be disabled
                                  const isDisabled = Number(item.id) <= Number(row.status_code) || Number(item.id) > 1; // Disable if "3" is selected
                                  return (
                                    <MenuItem key={index} value={item.id} disabled={isDisabled}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              :
              <p className={classes.no_data}>No orders found</p>
          }
        </TableContainer>
        {
          rows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalOrders}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )
        }
      </Paper>
      <SnackbarComponent
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackBarseverity}
        autoHideDuration={3000}
      />
      <ViewModal
        title={"Order Acknowledgement"}
        modalOpen={fileUploadModal}
        modalContent={RenderModalContent}
        trigger={setFileUploadModal}
        actionButtons={RenderCta}
      />

    </Box>
  );
}
