import './globalStyles/index.css'
import { Provider } from 'react-redux';
import store from './modules/dealer/store/reduxStore';
import Dealers from './routes/Dealers';
import { ToastContainer, toast } from "react-toastify"
import Marketing from './routes/Marketing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

// const routes = createBrowserRouter([...dealerRoutes,...marketRoutes]);

function App() {
  const pathName = window.location.pathname;

  return (
    <>
      <ToastContainer stacked autoClose={3000} />
      {pathName.includes('operations') ? <Marketing /> : <QueryClientProvider client={queryClient}><Dealers /></QueryClientProvider>}
    </>
  )
}

export default App;
