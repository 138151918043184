import React, { useEffect, useState } from "react"
import classes from "./ViewComplaint.module.css"
import SubHeaderNewPage from "../../features/Header/SubHeaderNewPage"
import { useParams } from "react-router-dom"
import useApiHttp from "../../hooks/ues-http"
import PdfView from "../../components/UI/PdfView/PdfView"
import FileViewerLightbox from "../../components/UI/PdfView/Lightbox/LightboxImgPdf"
const ViewComplaint = () => {
  const { token } = useParams()
  const [complaintDitails, setComplaintDitails] = useState([])

  const tabs = [
    { title: "Raise Complaint", path: "/raisecomplaint" },
    { title: ">", path: `/raisecomplaint` },
    { title: `Open Complaints`, path: `/raisecomplaint` },
    { title: ">", path: `/view_complaint/${token}` },
    {
      title: `${complaintDitails?.complaint_id}`,
      path: `/view_complaint/${token}`,
    },
  ]
  const {
    isLoading: ComplaintLoading,
    success: ComplaintSucces,
    error: ComplaintError,
    sendRequest: ComplaintRequest,
  } = useApiHttp()
  const ComplaintRequestHandle = () => {
    ComplaintRequest(
      {
        url: `dealer/raise-complaint/view`,
        method: "POST",
        body: {
          complaint_token: token,
        },
      },
      data => {
        setComplaintDitails(data.data)
      }
    )
  }
  useEffect(() => {
    ComplaintRequestHandle()
  }, [token])

  return (
    <div className={classes.myProfile}>
      <SubHeaderNewPage tabs={tabs} />
      <div className={classes.mrg_top}>
        <div className={`${classes.ditails_box} ${classes.alignment}`}>
          <div className={classes.main_contact_persaon}>
            <div className={classes.contact_persaon}>
              <div className={classes.contact_persaon_ditails}>
                <p>PO Number</p>
                <h4>
                  {complaintDitails?.po_number
                    ? complaintDitails?.po_number
                    : "-"}
                </h4>
              </div>
              <div className={classes.contact_persaon_ditails}>
                <p>Complaint Raised On</p>
                <h4>{complaintDitails?.created_at} </h4>
              </div>
              <div className={classes.contact_persaon_ditails}>
                <p>Status</p>
                <label
                  className={`${
                    complaintDitails.status === 0
                      ? "status_widget_beige"
                      : complaintDitails.status === 2
                      ? "status_widget_purple"
                      : "status_widget_green"
                  }`}
                >
                  {complaintDitails.status === 0 && "Sent"}
                  {complaintDitails.status === 1 && "Yet to be picked"}
                  {complaintDitails.status === 2 && "Under Investigation"}
                  {complaintDitails.status === 3 && "Resolved"}
                </label>
              </div>
            </div>
          </div>
          <div className={classes.contact_persaon_ditails}>
            <p>
              Product Details -{" "}
              <span className={classes.higlight}>
                {complaintDitails?.products?.length} Products
              </span>
            </p>

            <div className="">
              {complaintDitails?.products?.map((item, index) => (
                <div key={index} className={classes.product_name}>
                  <p>{item.product_code}</p>
                  <h5>{item.name}</h5>
                </div>
              ))}
            </div>
            <div
              className={classes.contact_persaon_ditails}
              style={{ marginTop: "12px" }}
            >
              <p>Complaint Details</p>
              <h4>{complaintDitails?.description}</h4>
            </div>

            <div
              className={classes.contact_persaon_ditails}
              style={{ marginTop: "12px" }}
            >
              <p>Attachments</p>

              <div
                style={{
                  width: "180px",
                  maxHeight: " 200px",
                  overflow: "hidden",
                  border: "1px solid #848484",
                  borderRadius: "3px",
                  marginTop: "0.5rem",
                }}
              >
                <PdfView fileUrl={complaintDitails?.attachment_url} />
              </div>

              <FileViewerLightbox fileUrl={complaintDitails?.attachment_url} />
            </div>

            <div
              className={classes.contact_persaon_ditails}
              style={{ marginTop: "12px" }}
            >
              <p>Status Comments</p>
              <label
                className={`${
                  complaintDitails.status === 0
                    ? "status_widget_beige"
                    : complaintDitails.status === 2
                    ? "status_widget_purple"
                    : "status_widget_green"
                }`}
              >
                {complaintDitails.status === 0 && "Sent"}
                {complaintDitails.status === 1 && "Yet to be picked"}
                {complaintDitails.status === 2 && "Under Investigation"}
                {complaintDitails.status === 3 && "Resolved"}
              </label>
            </div>
            <div
              className={classes.contact_persaon_ditails}
              style={{ marginTop: "12px" }}
            >
              <h4>
                {complaintDitails.comments
                  ? complaintDitails.comments
                  : "We're looking into your complaint and will get back to you shortly."}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewComplaint
