import React, { useMemo, useState, useEffect, useCallback } from "react"
import OtpInputs from "../../../components/UI/Otp/OtpInput"
import useCounter from "../../../utils/use-counter"
import { Button, Stack, Typography } from "@mui/material"
import CustomButton from "../../../components/UI/Button/Button"
import classes from "./OtpForm.module.css"
import { json, useNavigate } from "react-router-dom"

import timerIcon from "../../../assets/image/svg/otp timer.svg"
import useApiHttp from "../../../hooks/ues-http"
import { useSelector, useDispatch } from "react-redux"
import { authActions } from "../../../services/storeSlice/authSlice"
import { isAuthenticated } from "../../../services/isAuthenticated"
import { toast, ToastContainer } from "react-toastify"

const OtpForm = () => {
  const [otp, setOtp] = useState([])
  const [reset, setReset] = useState(false)
  const [otpCounter, startCounter, stopCounter, restartCounter] = useCounter(
    30,
    0,
    false
  )
  const navigate = useNavigate()
  const {
    isLoading: sendOtpLoading,
    success: sendOtpSucces,
    error: sendOtpError,
    sendRequest: sendOtp,
  } = useApiHttp()
  const {
    isLoading: verifyOtpLoading,
    success: verifyOtpSucces,
    error: verifyOtpError,
    sendRequest: verifyOtp,
  } = useApiHttp()
  const { mobile, dialCode } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  if (isAuthenticated()) {
    navigate("/")
  } else if (mobile === "") {
    navigate("/login")
  }
  useEffect(() => {
    startCounter()

    // return () => {
    //   second
    // }
  }, [])

  // const otpInputChangeHandler = otp => {
  //   setOtp(otp)
  // }
  const otpInputChangeHandler = useCallback(otp => {
    setOtp(otp)
  }, [])

  const formSubmitHandler = e => {
    e.preventDefault()
    setReset(true)
    verifyOtp(
      {
        url: `dealer/verify-otp`,
        method: "POST",
        body: {
          country_code: String(dialCode),
          mobile_number: mobile,
          otp: otp.join(""),
        },
      },
      data => {
        dispatch(authActions.login(data.data))
        localStorage.setItem("userEmail", data?.data?.email)
        localStorage.setItem("userName", data?.data?.name)
        localStorage.setItem("contactName", data?.data?.contact_name)
        navigate("/")
      }
    )
  }
  useEffect(() => {
    if (verifyOtpSucces !== "") {
      toast.success(verifyOtpSucces, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (verifyOtpError !== "") {
      toast.error(verifyOtpError, {
        // position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [verifyOtpSucces, verifyOtpError])

  return (
    <div className={classes.loginFrom}>
      <div className={classes.loginHeader}>
        <h3>Enter OTP</h3>
        <div className={classes.mobil_text}>
          {`+${dialCode} ${mobile}`}
          <p onClick={() => navigate("/login")}>Change</p>
        </div>
      </div>

      <form action="" onSubmit={formSubmitHandler}>
        <OtpInputs
          mt="20px"
          boxCount={6}
          onChange={otpInputChangeHandler}
          //   onSuccess={isSuccess || isError || reset ? "true" : "false"}
        />
        <Stack direction="row" justifyContent="center" mt="10px">
          {otpCounter > 0 && (
            <Typography
              variant="subtitle1"
              color="primary"
              sx={{ display: "flex", gap: "3px", alignItems: "center" }}
            >
              <img src={timerIcon} alt="time icon" width={"16px"} />
              <span className={classes.timer}>{otpCounter} sec</span>
            </Typography>
          )}
          {otpCounter === 0 && (
            <div>
              <Button
                variant="text"
                onClick={() => {
                  restartCounter()
                  setReset(true)
                  setOtp([])
                  sendOtp({
                    url: `dealer/send-otp`,
                    method: "POST",
                    body: {
                      country_code: String(dialCode),
                      mobile_number: mobile,
                    },
                  })
                }}
                sx={{
                  ml: "-8px",
                  fontSize: "15px",
                  fontFamily: "var(--font-bold)",
                  color: "var(--primary-blue)",
                  textTransform: "capitalize",
                  padding: 0,
                }}
              >
                Resend OTP
              </Button>
            </div>
          )}
        </Stack>

        <CustomButton
          variant="contained"
          customColor="white"
          customBgColor="#32349b"
          custmstyle={{ padding: "7px 7px", width: "100%", marginTop: "15px" }}
          type="submit"
        >
          Verify OTP
        </CustomButton>
      </form>
      <ToastContainer />
    </div>
  )
}

export default OtpForm
